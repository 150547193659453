import React from 'react'
import { Field } from 'formik'

import './checkbox.sass'

const Checkbox = props => {
  const { type, name, value, checked, onChange } = props
  // if no onChange, leave default onChange (setFieldValue)
  const onChangeProp = onChange ? { onChange } : {}

  return (
    <div>
      <div className={`checkbox-border ${checked ? 'checkbox-checked' : ''}`}>
        <div className={checked ? 'checkbox-tick' : ''} />
      </div>
      <Field {...onChangeProp} type={type} name={name} value={value} checked={checked} />
    </div>
  )
}

export default Checkbox
