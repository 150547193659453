import React, { Component } from 'react'
import { formatPrice } from 'Helpers/format-helper'

import './confirm-payment-interval-modal.sass'

class ConfirmPaymentIntervalModal extends Component {
  render() {
    const { currentInterval, basePrice, balance, proration, close, onValidate } = this.props
    const price = currentInterval === 'year' ? basePrice : basePrice * 11

    // after change in specs, (price < proration + balance) cannot happen anymore. changing from yearly to monthly is only allowed during the last month so user can never have a balance different from 0.
    const content =
      price > proration + balance ? (
        <div>
          Vous serez prélevé du montant suivant :<br />
          {formatPrice(price, true)} - {formatPrice(proration, true)} (remboursement du prorata de
          votre abonnement en cours)
          {balance
            ? ` - ${formatPrice(balance, true)} (avoir de votre précédent paiement)`
            : ''} = {formatPrice(price - proration - balance, true)} HT
          <br />
        </div>
      ) : (
        <div>
          Le montant restant de votre abonnement déjà réglé est de : {formatPrice(proration, true)}{' '}
          HT{balance ? `, et le montant de votre avoir est : ${formatPrice(balance, true)} HT` : ''}
          . Ce montant sera déduit de vos prochaines factures.
        </div>
      )

    return (
      <div className="ConfirmPaymentIntervalModal">
        <div className="content">
          {content}
          Confirmer la modification de la fréquence de paiement ?
        </div>
        <div className="buttons">
          <button onClick={close} type="button" className="btn valid connexion-btn">
            Annuler
          </button>
          <button
            onClick={() => {
              onValidate()
              close()
            }}
            type="button"
            className="btn valid connexion-btn"
          >
            Valider
          </button>
        </div>
      </div>
    )
  }
}

export default ConfirmPaymentIntervalModal
