export default function retry(fn, retriesLeft = 3, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        if (retriesLeft === 0) {
          // reject('maximum retries exceeded');
          reject(error)
          window.location.reload()

          return
        }

        setTimeout(() => {
          console.log('Retrying...')
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
