import React, { Component } from 'react'

export default class LetterBoxPagination extends Component {
  render() {
    const { totalPages, currentPage } = this.props

    return (
      totalPages > 1 && (
        <div className="letter-pagination">
          {currentPage > 0 && this.renderArrow(true)}
          {this.renderBlock(0)}
          {currentPage > 2 && this.renderPoints()}
          {this.renderBlocks()}
          {currentPage < totalPages - 3 && this.renderPoints()}
          {totalPages > 1 && currentPage < totalPages - 1 && this.renderBlock(totalPages - 1)}
          {currentPage < totalPages - 1 && this.renderArrow(false)}
        </div>
      )
    )
  }

  renderBlocks() {
    const { totalPages, currentPage } = this.props

    return (
      <React.Fragment>
        <div className="letter-pagination-extremum">
          {currentPage > 2 && this.renderBlock(currentPage - 2)}
        </div>
        {currentPage > 1 && this.renderBlock(currentPage - 1)}
        {currentPage > 0 && this.renderBlock(currentPage)}
        {currentPage < totalPages - 2 && this.renderBlock(currentPage + 1)}
        <div className="letter-pagination-extremum">
          {currentPage < totalPages - 3 && this.renderBlock(currentPage + 2)}
        </div>
      </React.Fragment>
    )
  }

  renderArrow(left) {
    const { currentPage, onSelectPage } = this.props

    return (
      <div
        className="letter-pagination-btn letter-pagination-selected"
        onClick={() => {
          onSelectPage(currentPage + (left ? -1 : 1))
        }}
      >
        <img
          src="/assets/icons/ic-arrow-right.svg"
          className={left ? 'letter-arrow-left' : ''}
          alt={left ? 'Precedent' : 'Suivant'}
        />
      </div>
    )
  }

  renderPoints() {
    return <div className="letter-pagination-points">...</div>
  }

  renderBlock(value) {
    const { currentPage, onSelectPage, totalPages } = this.props

    return (
      value < totalPages && (
        <div
          className={`letter-pagination-btn ${
            currentPage === value ? 'letter-pagination-selected' : ''
          }`}
          onClick={() => onSelectPage(value)}
        >
          {value + 1}
        </div>
      )
    )
  }
}
