export const stripeIbanCall = (stripe, allForms, name, promoCode) => {
  if (!stripe) return
  const userData = {
    ...allForms.step0,
    ...allForms.step1,
    ...allForms.step2,
    ...allForms.step3,
    ...allForms.step4,
    promoCode,
  }

  return stripe
    .createSource({
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name,
        email: allForms.step0.email,
      },
      mandate: {
        notification_method: 'email',
      },
    })
    .then(source => {
      return fetch('/api/subscribe/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          stripeToken: source.source.id,
          userData,
          isSepa: true,
        }),
      })
    })
    .then(res => {
      if (res.status !== 200 && res.status !== 403) throw new Error(res.error)

      return res
    })
    .then(result => result.json())
}

export const editIbanCall = async (stripe, { name, email, id }) => {
  if (!stripe) return

  return stripe
    .createSource({
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name,
        email,
      },
      mandate: {
        notification_method: 'email',
      },
    })
    .then(({ source }) =>
      fetch(`/api/user/${id}/sepa`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          source: source.id,
        }),
      })
        .then(res => {
          if (res.status !== 200) throw new Error(res.error)

          return res.json()
        })
        .then(user => ({ user, source })),
    )
}
