import React from 'react'
import { Field } from 'formik'

import './radio.sass'

const Radio = props => {
  const { type, name, value, checked, onChange, isNotFormikField } = props
  // if no onChange, leave default onChange (setFieldValue)
  const onChangeProp = onChange ? { onChange } : {}

  return (
    <div>
      <div className={`radio-border ${checked ? 'radio-checked' : ''}`}>
        <div className={checked ? 'radio-tick' : ''} />
      </div>
      {isNotFormikField
        ? <input {...onChangeProp} type={type} name={name} value={value} checked={checked} />
        : <Field {...onChangeProp} type={type} name={name} value={value} checked={checked} />
      }
    </div>
  )
}

export default Radio
