import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from 'Actions/ui'

import './notification-modal.sass'

class NotificationModal extends Component {
  render() {
    const { title, message, dispatch, onButtonClick, buttonText, unclosable } = this.props

    return (
      <div className="notif-modal-container">
        {!unclosable &&
          <img onClick={() => dispatch(closeModal())} src="/assets/icons/icon-cross.svg" className="notif-modal-close" alt="fermer" />
        }
        {title && <div className="notif-modal-title">{title}</div>}
        <div className="notif-modal-message">{message}</div>
        <div
          className="btn valid close-btn"
          onClick={() => {
            if (onButtonClick) onButtonClick()
            dispatch(closeModal())
          }}
        >
          {buttonText || 'Ok'}
        </div>
      </div>
    )
  }
}

export default connect()(NotificationModal)
