import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'
import CustomerAreaTitle from '../Display/CustomerAreaTitle'
import Button from '../../ui/Button'
import fetchWrapper from 'Helpers/fetch-wrapper'

import './formalites-page.sass'

// date after which formalites can display suivi, because formalists start using forestadmin
const FORMALITE_CHANGE_DATE = moment('13/09/2023', 'DD/MM/YYYY')

class FormalitePage extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      existingTransfert: null,
      transfertType: null,
      transfertLoaded: false,
      existingCreation: null,
      creationType: null,
      creationLoaded: false,
    }
  }

  componentDidMount() {
    fetchWrapper('/api/formalites/transfert-siege/current-user')
      .then(res => {
        this.setState({
          existingTransfert: res.transfert,
          transfertType: res.type,
          transfertLoaded: true,
        })
      })
    fetchWrapper('/api/company-creation/current-user')
      .then(res => {
        this.setState({
          existingCreation: res.companyCreation,
          creationType: res.type,
          creationLoaded: true,
        })
      })
  }

  renderTransfert() {
    const { existingTransfert, transfertLoaded } = this.state
    if (!transfertLoaded) return null
    
    if (!existingTransfert || !existingTransfert.finalized || moment(existingTransfert.created_at).isBefore(FORMALITE_CHANGE_DATE)) {
      return this.renderTransfertButton()
    }
    
    return this.renderTransfertStatus()
  }

  renderAssignee(assignee) {
    if (!assignee) return null
    const email = assignee === 'Robin'
      ? 'rcformalites@gmail.com'
      : 'speedformalis@gmail.com'

    return (
      <div className="formalite-status-subtitle mt-6">
        Pour toute question vous pouvez contacter notre formaliste par email à cette adresse :<br/>{email}
      </div>
    )
  }
  
  renderTransfertStatus() {
    const { kbis_obtenu } = this.state.existingTransfert
    const isMicro = this.state.transfertType === 'microTransfert'
    let step = 1
    
    if (isMicro) {
      const { pouvoir, depot_inpi } = this.state.existingTransfert

      if (pouvoir && depot_inpi) step = 2
    } else {
      const { paiement_depot_greffe } = this.state.existingTransfert
      
      if (paiement_depot_greffe) step = 2
    }
    
    if (kbis_obtenu) step = 3

    return (
      <div className="formalite-status">
        <div className="formalite-status-title">Suivi de votre formalité</div>
        <div className="formalite-status-list">
          <div className={`formalite-status-item${step=== 1 ? ' active' : ''}`}>1. Signature de vos documents juridiques</div>
          {step === 1 && (
            <div className="formalite-status-subtitle">Votre formaliste va vous envoyer sous 48h une série de documents à signer via l'outil Yousign. Vérifiez régulièrement votre boite mail.</div>
          )}
          <div className={`formalite-status-item${step=== 2 ? ' active' : ''}`}>2. Dépôt de votre dossier auprès {isMicro ? 'de l’INPI' : 'du greffe'}</div>
          {step === 2 && (
            <div className="formalite-status-subtitle">Votre dossier a été déposé auprès {isMicro ? 'de l’INPI' : 'du greffe'}. Nous attendons que le greffe traite votre dossier. Attention : les délais sont très variables, et peuvent aller jusqu'à plusieurs semaines.</div>
          )}
          <div className={`formalite-status-item${step=== 3 ? ' active' : ''}`}>3. Votre nouveau kbis</div>
          {step === 3 && (
            <div className="formalite-status-subtitle">Votre nouveau Kbis vous a été envoyé par email !</div>
          )}
        </div>
        {this.renderAssignee(this.state.existingTransfert?.assignee)}
      </div>
    )
  }
  
  renderTransfertButton() {
    const { juridic_form, id, contracts } = this.props.currentUser
    const { existingTransfert } = this.state

    if (contracts[0]?.address?.status !== 1) {
      return 'Formalités non disponibles, merci de vous rapprocher du service client (contact@ma-domiciliation.fr)'
    }

    if (!['SAS', 'SASU', 'SARL', 'EURL', 'SCI', 'Micro-entreprise', 'EI'].includes(juridic_form)) {
      return `Cette formalité n'est pas disponible pour votre forme juridique (${juridic_form}).`
    }
    
    const urlPrefix = (juridic_form === 'Micro-entreprise' || juridic_form === 'EI') ? '/micro' : ''

    if (existingTransfert) {
      if (existingTransfert.finalized) {
        return 'Vous avez déjà terminé une demande de transfert de siège.'
      }
      
      let urlPart = 'transfert-de-siege'
      if (existingTransfert.invoice_id) urlPart = 'transfert-de-siege-dossier'
      if (existingTransfert.transfer_date) urlPart = 'transfert-de-siege-actionnaires'

      return (
        <Link to={`${urlPrefix}/${urlPart}?transfert_id=${existingTransfert.id}`}>
          <Button label="Finaliser ma formalité" secondaryClass="primary" />
        </Link>
      )
    }

    return (
      <Link to={`${urlPrefix}/transfert-de-siege?user_id=${id}`}>
        <Button label="Obtenir mon prix" secondaryClass="primary" />
      </Link>
    )
  }
  
  renderCreation() {
    const { existingCreation, creationLoaded } = this.state
    if (!creationLoaded) return null
    
    if (!existingCreation || !existingCreation.finalized || moment(existingCreation.created_at).isBefore(FORMALITE_CHANGE_DATE)) {
      return this.renderCreationButton()
    }
    
    return this.renderCreationStatus()
  }

  renderCreationStatus() {
    const { kbis_obtenu } = this.state.existingCreation
    const isMicro = this.state.creationType === 'microCreation'
    let step = 1
    
    if (isMicro) {
      const { pouvoir, dnc, depot_inpi } = this.state.existingCreation

      if (pouvoir && dnc && depot_inpi) step = 2
    } else {
      const { envoi_greffe } = this.state.existingCreation
      
      if (envoi_greffe) step = 2
    }
    
    if (kbis_obtenu) step = 3

    return (
      <div className="formalite-status">
        <div className="formalite-status-title">Suivi de votre formalité</div>
        <div className="formalite-status-list">
          <div className={`formalite-status-item${step=== 1 ? ' active' : ''}`}>1. Signature de vos documents juridiques</div>
          {step === 1 && (
            <div className="formalite-status-subtitle">Votre formaliste va vous envoyer sous 48h une série de documents à signer via l'outil Yousign. Vérifiez régulièrement votre boite mail.</div>
          )}
          <div className={`formalite-status-item${step=== 2 ? ' active' : ''}`}>2. Dépôt de votre dossier auprès {isMicro ? 'de l’INPI' : 'du greffe'}</div>
          {step === 2 && (
            <div className="formalite-status-subtitle">Votre dossier a été déposé auprès {isMicro ? 'de l’INPI' : 'du greffe'}. Nous attendons que le greffe traite votre dossier. Attention : les délais sont très variables, et peuvent aller jusqu'à plusieurs semaines.</div>
          )}
          <div className={`formalite-status-item${step=== 3 ? ' active' : ''}`}>3. Votre nouveau kbis</div>
          {step === 3 && (
            <div className="formalite-status-subtitle">Votre nouveau Kbis vous a été envoyé par email !</div>
          )}
        </div>
        {this.renderAssignee(this.state.existingCreation?.assignee)}
      </div>
    )
  }
  
  renderCreationButton() {
    const { juridic_form, id, has_pending_prepaid_company_creation, contracts } = this.props.currentUser
    const { existingCreation } = this.state

    if (contracts[0]?.address?.status !== 1) {
      return 'Formalités non disponibles, merci de vous rapprocher du service client (contact@ma-domiciliation.fr)'
    }

    if (!['SAS', 'SASU', 'SARL', 'EURL', 'SCI', 'Micro-entreprise', 'EI'].includes(juridic_form)) {
      return `Cette formalité n'est pas disponible pour votre forme juridique (${juridic_form}).`
    }
    
    const urlPrefix = (juridic_form === 'Micro-entreprise' || juridic_form === 'EI') ? '/micro' : ''

    if (existingCreation) {
      if (existingCreation.finalized) {
        return 'Vous avez déjà terminé une demande de création de société.'
      }

      if (existingCreation.invoice_id // check if already paid
        && !has_pending_prepaid_company_creation) { // check if it was not pre-paid on a CompanyCreationOnboarding, in which case user must fill first forms
        return (
          <Link to={`${urlPrefix}/creation-entreprise-dossier?company_creation_id=${existingCreation.id}`}>
            <Button label="Finaliser ma formalité" secondaryClass="primary" />
          </Link>
        )
      }
      
      return (
        <Link to={`${urlPrefix}/creation-entreprise-informations?company_creation_id=${existingCreation.id}`}>
          <Button label="Finaliser ma formalité" secondaryClass="primary" />
        </Link>
      )
    }
    
    return (
      <Link to={`${urlPrefix}/creation-entreprise-informations?user_id=${id}`}>
        <Button label="Obtenir mon prix" secondaryClass="primary" />
      </Link>
    )
  }

  render() {
    return (
      <CustomerNavigation currentPage="formalites">
        <div className="FormalitePage customer-area-content-mobile-container">
          <CustomerAreaTitle label="Formalités Juridiques" icon="ic-mon-contrat" />
          <div className="formalite-description">
            L'équipe de ma-domiciliation.fr peut prendre en charge le transfert de votre siège, ou la création de votre société. Cliquez ci-dessous pour obtenir un devis détaillé.
          </div>
          <div className="formalite-content mb-32">
            <span className="formalite-name">Transfert de siège&nbsp;:</span>
            {this.renderTransfert()}
          </div>
          <div className="formalite-content">
            <span className="formalite-name">Création de société&nbsp;:</span>
            {this.renderCreation()}
          </div>
        </div>
      </CustomerNavigation>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(FormalitePage)
