import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import handleValidate from 'Helpers/validators'

import AddressGeocode from 'Ui/AddressGeocode'
import { closeModal } from '../../../actions/ui'
import { notify } from '../../../actions/notifications'
import { setCurrentUser } from '../../../actions/user'
import { getUser } from '../../../api/user'

import './editAddressModal.sass'

const fieldValidators = {
  name: ['required'],
  expeditionAddress: ['required'],
}

class EditAddressModal extends Component {
  constructor(props) {
    super(props)
    const {
      expedition_street,
      expedition_cp,
      expedition_city,
      expedition_country,
    } = this.props.currentUser

    this.state = {
      address: {
        street: expedition_street,
        cp: expedition_cp,
        city: expedition_city,
        country: expedition_country,
        formattedAddress: this.formatAddress(),
      },
    }
  }

  addressValidation(address) {
    if (!address) {
      return { expeditionAddress: 'Merci d’indiquer une adresse de réexpédition' }
    }

    if (!this.state.address) {
      return { expeditionAddress: 'Adresse de réexpédition imprécise.' }
    }

    if (address !== this.state.address.formattedAddress) {
      return {
        expeditionAddress: 'Adresse non valide',
      }
    }
  }

  handleAddressSelect(address, setFieldValue) {
    this.setState(
      {
        ...this.state,
        address,
      },
      () => {
        setFieldValue('expeditionAddress', address.formattedAddress)
      },
    )
  }

  fixMissingStreet() {
    const { address } = this.state
    if (
      !address.street.trim() &&
      address.formattedAddress &&
      address.formattedAddress.split(',')[0]
    ) {
      return { ...address, street: address.formattedAddress.split(',')[0] }
    }

    return address
  }

  handleSubmit(values) {
    const { dispatch, currentUser } = this.props
    const address = this.fixMissingStreet()

    // save formatted address instead of raw user input
    fetch(`/api/user/${currentUser.id}/expedition-address`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        ...values,
        ...address,
      }),
    })
      .then(res => res.json())
      .then(() => {
        this.props.close()
        getUser(res => {
          dispatch(setCurrentUser(res))
          dispatch(notify('success', 'Modification validée !'))
        })
      })
      .catch(() => dispatch(notify('error', 'Une erreur est survenue')))
  }

  handlePremise(value, setFieldValue) {
    setFieldValue('premise', value && value.length >= 35 ? value.substring(0, 34) : value)
    this.setState({ premise: value && value.length >= 35 ? value.substring(0, 34) : value })
  }

  formatAddress() {
    const {
      expedition_street,
      expedition_cp,
      expedition_city,
      expedition_country,
    } = this.props.currentUser
    
    if (!expedition_city) return ''
    
    return `${expedition_street}, ${expedition_cp} ${expedition_city}, ${expedition_country}`
  }

  render() {
    const { currentUser, formOnly, fullWidth, close } = this.props
    const { expedition_name, expedition_premise } = currentUser

    return (
      <div className={`edit-address-modal ${fullWidth ? 'full-width' : ''}`}>
        {!formOnly && (
          <div className="edit-address-title">Votre adresse de réexpédition de courrier</div>
        )}
        <Formik
          initialValues={{
            name: expedition_name || '',
            expeditionAddress: this.formatAddress(),
            premise: expedition_premise || '',
          }}
          validate={values => {
            return {
              ...handleValidate(fieldValidators)(values),
              ...this.addressValidation(values.expeditionAddress),
            }
          }}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ setFieldValue, setFieldError, errors, touched }) => {
            return (
              <Form>
                <div className="edit-address-input">
                  <div className="edit-address-label">A l'attention de</div>
                  <div className="input-with-icon">
                    <div className="input-icon icon-representant" />
                    <Field
                      name="name"
                      type="text"
                      className="Input-group"
                      placeholder="Destinataire"
                    />
                    <span className="input-error-message">
                      {(touched.name && errors.name) || ''}
                    </span>
                  </div>
                </div>
                <div className="edit-address-input">
                  <div className="edit-address-label">Adresse</div>
                  <div className="input-with-icon">
                    <div className="input-icon icon-address" />
                    <Field
                      name="expeditionAddress"
                      component={AddressGeocode}
                      onChange={setFieldValue.bind(this, 'expeditionAddress')}
                      onSelect={address => this.handleAddressSelect(address, setFieldValue)}
                      onError={error => setFieldError('expeditionAddress', error)}
                      className="Input-group input"
                      placeholder="Exemple : 2 Rue Villedo, 75001 Paris"
                    />
                    <span className="input-error-message">
                      {(touched.expeditionAddress && errors.expeditionAddress) || ''}
                    </span>
                  </div>
                </div>
                <div className="edit-address-input">
                  <div className="edit-address-label">Complément d'adresse</div>
                  <div className="input-with-icon">
                    <div className="input-icon icon-address" />
                    <Field
                      name="premise"
                      type="text"
                      onChange={value => this.handlePremise(value.target.value, setFieldValue)}
                      className="Input-group"
                      placeholder="Complément d'adresse"
                    />
                    <span className="input-error-message">
                      {(touched.premise && errors.premise) || ''}
                    </span>
                  </div>
                </div>
                <div className="edit-address-button-line">
                  {!formOnly && (
                    <div className="btn valid close-btn" onClick={() => close()}>
                      Annuler
                    </div>
                  )}
                  <button type="submit" className="btn valid connexion-btn">
                    Valider
                  </button>
                </div>
              </Form>
            )
          }}
        />
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})


export default connect(MapStateToProps)(EditAddressModal)
