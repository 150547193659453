/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { RECEIVE_ADDRESSES, SET_ADRESSES_LOADING } from '../actions/index'

type AddressesInitialState = {
  addresses: Address[]
  isLoading: boolean
}

const initialState = {
  addresses: [],
  isLoading: false,
}

const addresses = (state: AddressesInitialState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case RECEIVE_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses,
        isLoading: false,
      }
    case SET_ADRESSES_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    default:
      return state
  }
}

export default addresses
