import React from 'react'
import { IbanElement, injectStripe } from 'react-stripe-elements'

//TEST DE89 3704 0044 0532 0130 00

import './ibanForm.sass'

const styleOptions = {
  style: {
    base: {
      color: '#5c5f65',
      fontSize: '15px',
      lineHeight: '55px',
      '::placeholder': {
        color: '#8e9299',
        fontSize: '15px',
        lineHeight: '55px',
      },
    },
    invalid: {
      color: '#f6503e',
    },
  },
}

class IbanForm extends React.Component {
  constructor(props) {
    super(props)

    // only error messages
    this.state = {
      error: null,
      complete: false,
      name: '',
    }
  }

  handleSubmit(ev) {
    ev.preventDefault()
    const { complete, name } = this.state
    if (!complete || !name) return

    const { handleSubmit, stripe } = this.props

    handleSubmit(stripe, this.state.name)
  }

  handleChange(change) {
    const { complete, error } = change

    this.setState({ error: error && error.message, complete })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className="Payment-form">
        <div className="means-container">
          <input type="submit" id="submit-sepa-form" style={{ display: 'none' }} />
          <div className="Payment-input-wrapper">
            <div className="input field_group">
              <label className="a-input_label">
                <input
                  type="text"
                  name="name"
                  placeholder="Nom du titulaire du compte*"
                  onChange={e => this.setState({ name: e.target.value })}
                  value={this.state.name}
                />
              </label>
            </div>
            <div className="input field_group">
              <label className="a-input_label">
                <div className="stripe-input-wrapper input-stripe">
                  <IbanElement
                    supportedCountries={['SEPA']}
                    placeholderCountry="FR"
                    placeholder="Votre IBAN"
                    onChange={e => this.handleChange(e)}
                    hideIcon={true}
                    {...styleOptions}
                  />
                </div>
                <div className="input-error-message">{this.state.error || ''}</div>
              </label>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default injectStripe(IbanForm)
