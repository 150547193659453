import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import handleValidate from 'Helpers/validators'
import { notify } from '../../../actions/notifications'
import { getUser } from '../../../api/user'
import { setCurrentUser } from 'Actions/user'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AmlModal/aml-modal.sass'

const fieldValidators = {
  choix_domiciliation: ['required', 'min-length-4'],
}

class ChoixDomiciliationModal extends Component {
  handleSubmit(values, actions) {
    const { dispatch, currentUser, close } = this.props

    fetch(`/api/user/${currentUser.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(values),
    })
      .then(res => {
        actions.setSubmitting(false)
        if (res.status !== 200) throw new Error(res.error)
        return res
      })
      .then(res => res.json())
      .then(() => {
        dispatch(notify('success', 'Information enregistrée avec succès.'))
        close()
        getUser(res => {
          dispatch(setCurrentUser(res))
        })
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        if (e) dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }

  renderQuestion() {
    return (
      <Formik
        initialValues={{
          choix_domiciliation: '',
        }}
        validate={values => handleValidate(fieldValidators)(values)}
        onSubmit={(values, actions) => this.handleSubmit(values, actions)}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <div className="form-block">
                <div className="label-form">
                  <span className="step-subtitle">Merci d’indiquer ci-dessous la raison pour laquelle vous avez choisi de domicilier votre siège social au sein d’une société de domiciliation</span>
                </div>
                <Field name="choix_domiciliation" component="textarea" className="Input-group" rows={4} style={{ height: 'auto' }} />
                {this.renderSubmitButton(isSubmitting)}
              </div>
            </Form>
          )
        }}
      />
    )
  }

  renderSubmitButton(isSubmitting) {
    return (
      <div className="right-container mb-32">
        <SubmitSpinnerButton isSubmitting={isSubmitting}>
          <button type="submit" className="btn valid small-height">
            Valider
          </button>
        </SubmitSpinnerButton>
      </div>
    )
  }

  render() {
    return (
      <div className="AmlModal">
        <h3>Mise à jour réglementaire de votre fiche client</h3>
        {this.renderQuestion()}
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(ChoixDomiciliationModal)
