import React, { Component } from 'react'
import { Elements } from 'react-stripe-elements'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import IbanForm from '../../SubscriptionPage/PaymentForm/IbanForm'
import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import { editIbanCall } from 'Helpers/stripe-iban-call'
import { setCurrentUser } from 'Actions/user'
import { closeModal } from 'Actions/ui'
import { notify } from 'Actions/notifications'
import { StripeProvider } from 'react-stripe-elements'
import { STRIPE_PUBLIC_KEY } from '../../../config/constants'
import get from 'lodash/get'

import './PaymentMeans.sass'

class EditIbanModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isProcessing: false,
      isValidating: false,
      secrets: null,
      stripe: null,
    }
  }

  handleSepaSubmit(stripe, name) {
    const { dispatch, currentUser } = this.props

    this.setState({ isProcessing: true })

    return editIbanCall(stripe, {
      id: currentUser.id,
      name,
      email: currentUser.email,
    })
      .then(({ user, source }) => {
        if (!isEmpty(user.pendingPayments)) {
          // not very clean, need to keep cb form opened to keep access to stripe variable
          // so just hide the form and show validation message, and keep stripe variable in state because it's not accessible in props anymore when validating
          this.setState({ isValidating: true })
          this.handleSuccessivePayments(stripe, source, user.pendingPayments)

          return
        }
        this.setState({ isProcessing: false })
        dispatch(setCurrentUser(user))
        dispatch(notify('success', 'Modification enregistrée !'))
        dispatch(closeModal())
      })
      .catch(err => {
        this.setState({ isProcessing: false })
        console.log('[ERROR]: handleSepaSubmit ---------------->', err)
        dispatch(notify('error', 'Paiement refusé, merci de vérifier les informations entrées.'))
      })
  }

  handleSuccessivePayments(stripe, source, secrets, step = 0) {
    const { dispatch } = this.props

    stripe.confirmSepaDebitPayment(secrets[step], { payment_method: source.id }).then(res => {
      if (res.error) {
        dispatch(closeModal())
        dispatch(
          notify('error', 'Paiement refusé, veuillez vérifier l’approvisionnement de votre carte.'),
        )

        return
      }

      if (step < secrets.length - 1) {
        dispatch(notify('success', 'Paiement validé'))
        this.handleSuccessivePayments(stripe, secrets, step + 1)

        return
      }

      dispatch(
        notify('success', 'Tous les paiements ont été validés. Réactivation de votre compte...'),
      )

      dispatch(closeModal())

      this.checkPaymentsStatuses()
    })
  }

  checkPaymentsStatuses() {
    const { dispatch, currentUser } = this.props

    fetch(`/api/user/${currentUser.id}/check-invoices-paid`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(user => dispatch(setCurrentUser(user)))
  }

  // Submit different form depending on paymentMean
  renderSubmitButton() {
    return (
      <SubmitSpinnerButton isSubmitting={this.state.isProcessing} className="large">
        <label className={`payment-label-submit btn valid`} htmlFor="submit-sepa-form" tabIndex="0">
          MODIFIER
        </label>
      </SubmitSpinnerButton>
    )
  }

  render() {
    const { isValidating } = this.state
    const isValidatingClass = isValidating ? ' means-modal-validating' : ''
    const stripeAccount = get(this.props, 'currentUser.company.idStripeAccount', undefined)
      ? get(this.props, 'currentUser.company.idStripeAccount', undefined)
      : undefined

    return (
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY} stripeAccount={stripeAccount}>
        <div className={`means-modal${isValidatingClass}`}>
          <Elements locale="fr">
            <IbanForm
              handleSubmit={this.handleSepaSubmit.bind(this)}
              onChange={console.log}
              handleResult={console.log}
            />
          </Elements>
          {this.renderSubmitButton()}
        </div>
      </StripeProvider>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(EditIbanModal)
