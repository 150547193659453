import React, { Component } from 'react'
import { formatPrice } from 'Helpers/format-helper'

import './confirm-payment-interval-modal.sass'

const yearlyScanPrice = 110

class ConfirmScanModal extends Component {
  render() {
    const { balance, proration, close, onValidate } = this.props

    return (
      <div className="ConfirmPaymentIntervalModal">
        <div className="content">
          L'offre Scan est facturée 10€ HT/mois, soit 110€ HT/an avec un mois offert.<br />
          Vous allez être prélevé du prorata pour le reste de la période courante de votre abonnement, soit {formatPrice(proration, true)} HT.<br /><br />
          Confirmer la souscription à l'offre Scan ?
        </div>
        <div className="buttons">
          <button onClick={close} type="button" className="btn valid connexion-btn">
            Annuler
          </button>
          <button
            onClick={() => {
              onValidate()
              close()
            }}
            type="button"
            className="btn valid connexion-btn"
          >
            Valider
          </button>
        </div>
      </div>
    )
  }
}

export default ConfirmScanModal
