import { spinnerWhite } from '@assets/images'
import React, { FC, ReactElement, useMemo } from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: React.DOMAttributes<HTMLButtonElement>['onClick']
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'secondary' | 'link'
}

const Button: FC<Props> = ({
  children,
  onClick,
  className = '',
  isDisabled = false,
  isLoading = false,
  type = 'button',
  variant = 'primary',
}): ReactElement => {
  const tailwindClassNames = useMemo(() => {
    const base = 'flex items-center justify-center py-2.5 px-6 ff_poppins duration-300 w-full uppercase rounded'
    if (isDisabled || isLoading) {
      return `${base} bg-light-gray border text-[14px] border-dark-gray text-white shadow-button-disabled`
    }
    switch (variant) {
      case 'primary':
        return `${base} bg-[#F5BD41] hover:bg-white text-[14px] border border-[#F5BD41] hover:text-[#F5BD41] text-white shadow-button-primary`
      case 'secondary':
        return `${base} bg-[#eaf2ff] border text-[14px] border-[#6FA5FF] text-[#6FA5FF] hover:bg-[#6FA5FF] hover:text-white shadow-button-secondary`
      case 'link':
        return 'py-2 border-b border-b-2'
      default:
        return ''
    }
  }, [isDisabled, isLoading, variant])

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${tailwindClassNames} Button ${className}`}
      onClick={(isDisabled || isLoading) ? undefined : onClick}
      disabled={isDisabled || isLoading}
    >
      {isLoading
        ? (
          <img
            src={spinnerWhite}
            alt="Veuillez patienter"
            className="h-5 w-5 text-center"
          />
        )
        : children}
    </button>
  )
}

export default Button
