import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { find } from 'lodash'
import { GTM_ENV, WEBSITE_URL } from 'Config/constants'

const routePages = [
  // Root routes
  {
    url: '/',
    name: 'Home',
  },
  {
    url: '/home-v2',
    name: 'Home v2',
  },
  {
    url: '/lp1',
    name: 'Home v2 variant',
  },
  {
    url: '/home-transfert',
    name: 'Home Transfert',
  },
  {
    url: '/1euro',
    name: 'Home-1euro',
  },
  {
    url: '/inscription',
    name: 'Tunnel',
  },
  {
    url: '/adresse/:slug',
    name: 'Page Adresse',
  },
  {
    url: '/login',
    name: 'LoginPage',
  },
  {
    url: '/reinitialisation',
    name: 'PasswordResetPage',
  },
  {
    url: '/reinitialisation-mdp/',
    name: 'NewPasswordPage',
  },
  {
    url: '/contact',
    name: 'ContactUsPage',
  },
  {
    url: '/mon-compte',
    name: 'CustomerArea',
  },
  {
    url: '/cgu',
    name: 'CGUPage',
  },
  {
    url: '/cgv',
    name: 'CGvPage',
  },
  {
    url: '/politique-confidentialite',
    name: 'Politique Confidentialite',
  },
  // Tunnel nested routes
  {
    url: '/inscription/email',
    name: 'Tunnel étape 0',
  },
  {
    url: '/inscription/adresses',
    name: 'Tunnel étape 1',
  },
  {
    url: '/inscription/informations',
    name: 'Tunnel étape 2',
  },
  {
    url: '/inscription/options',
    name: 'Tunnel étape 3',
  },
  {
    url: '/inscription/paiement',
    name: 'Tunnel étape 4',
  },
  {
    url: '/inscription/password',
    name: 'Tunnel étape 5.1',
  },
  {
    url: '/inscription/contrat',
    name: 'Tunnel étape 5.2',
  },
  {
    url: '/inscription/signature',
    name: 'Tunnel étape 5.3',
  },
  {
    url: '/inscription/termine',
    name: 'Tunnel étape 5.4',
  },
  {
    url: '/inscription/validation',
    name: 'Tunnel page blocage validation',
  },
  // mon-compte nested routes
  {
    url: '/mon-compte/lettres',
    name: 'Mon compte - lettres',
  },
  {
    url: '/mon-compte/adresse',
    name: 'Mon compte - adresse',
  },
  {
    url: '/mon-compte/abonnement',
    name: 'Mon compte - abonnement',
  },
  {
    url: '/mon-compte/contrat',
    name: 'Mon compte - contrat',
  },
  {
    url: '/mon-compte/factures',
    name: 'Mon compte - factures',
  },
  {
    url: '/mon-compte/paiement',
    name: 'Mon compte - paiement',
  },
  {
    url: '/mon-compte/formalites',
    name: 'Mon compte - formalités',
  },
  {
    url: '/mon-compte/actualisation',
    name: 'Mon compte - actualisation',
  },
  // Formalites forms
  {
    url: '/mon-compte/formalites',
    name: 'Mon compte - formalités',
  },
  {
    url: '/formalite-payment-validated',
    name: 'Formalité - Formulaire validé',
  },
  {
    url: '/transfert-de-siege',
    name: 'Formalité - Formulaire transfert',
  },
  {
    url: '/transfert-de-siege-dossier',
    name: 'Formalité - Formulaire transfert 2',
  },
  {
    url: '/creation-entreprise-informations',
    name: 'Formalité - Formulaire création',
  },
  {
    url: '/creation-entreprise-dossier',
    name: 'Formalité - Formulaire création 2',
  },
  {
    url: '/micro/transfert-de-siege',
    name: 'Formalité - Formulaire transfert micro-entreprise',
  },
  {
    url: '/micro/transfert-de-siege-dossier',
    name: 'Formalité - Formulaire transfert micro-entreprise 2',
  },
  {
    url: '/micro/creation-entreprise-informations',
    name: 'Formalité - Formulaire création micro-entreprise',
  },
  {
    url: '/micro/creation-entreprise-dossier',
    name: 'Formalité - Formulaire création micro-entreprise 2',
  },
  {
    url: '/creation-entreprise',
    name: 'Création entreprise Home',
  },
  {
    url: '/micro/creation-entreprise-rendez-vous',
    name: 'Création entreprise rdv',
  },
  {
    url: '/creation-entreprise/onboarding',
    name: 'Création entreprise step onboarding',
  },
  {
    url: '/creation-entreprise/enregistrement',
    name: 'Création entreprise step enregistrement',
  },
  {
    url: '/creation-entreprise/offres',
    name: 'Création entreprise step offres',
  },
  {
    url: '/creation-entreprise/domiciliation',
    name: 'Création entreprise step domiciliation',
  },
  {
    url: '/creation-entreprise/adresses',
    name: 'Création entreprise step adresses',
  },
  {
    url: '/creation-entreprise/paiement',
    name: 'Création entreprise step paiement',
  },
  {
    url: '/creation-entreprise/confirmation-paiement',
    name: 'Création entreprise step confirmation-paiement',
  },
]

class GmtAndScrollWrapper extends Component {
  componentDidMount() {
    // user tracking

    const { location } = this.props
    const { pathname } = location

    let route = find(routePages, { url: pathname })

    if (!route) route = this.handleSlugs(pathname)

    if (!route) {
      console.log('No matching route found for analytics !')

      return
    }

    const isAccountPage = pathname.match(/^\/mon-compte\//)

    window.dataLayer = [
      {
        env_work: GTM_ENV || 'development',
        pageType: route.name,
        userStatut: isAccountPage ? 'oui' : 'non',
        pageURI: `${WEBSITE_URL}${pathname}`,
        pageTitle: route.name,
      },
    ]

    this.startGMT()
  }

  startGMT() {
    // eslint-disable-next-line no-extra-semi
    ;(function(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-NNW6S3G')
  }

  handleSlugs(pathname) {
    const addressPagesMatch = pathname.match(/^\/adresse\/(.*)$/)

    if (addressPagesMatch)
      return {
        url: pathname,
        name: `Page Adresse - ${addressPagesMatch[1]}`,
      }

    const resetPageMatch = pathname.match(/^\/reinitialisation-mdp\//)

    if (resetPageMatch)
      return {
        url: pathname,
        name: 'PasswordResetPage',
      }

    return null
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    // Reset scroll on page change
    if (location !== prevProps.location) {
      window.scrollTo(0, 0)
    }

    // User tracking

    // no GTM event if stays on same page (i.e. anchor link)
    if (location.pathname === prevProps.location.pathname) return

    const { pathname } = location
    let route = find(routePages, { url: pathname })

    if (!route) route = this.handleSlugs(pathname)

    if (!route) {
      console.log('No matching route found for analytics !')

      return
    }

    const isAccountPage = pathname.match(/^\/mon-compte\//)

    const data = {
      env_work: GTM_ENV || 'development',
      pageType: route.name,
      userStatut: isAccountPage ? 'oui' : 'non',
    }

    if (window.dataLayer) {
      window.dataLayer.push(data)
    } else {
      window.dataLayer = [data]
    }

    window.dataLayer.push({
      event: 'screenChange',
      pageURI: `${WEBSITE_URL}${pathname}`,
      pageTitle: route.name,
    })
  }

  render() {
    return this.props.children
  }
}

export default withRouter(GmtAndScrollWrapper)
