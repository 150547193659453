import React, { Component } from 'react'
import { connect } from 'react-redux'

import { GTM_ENV } from 'Config/constants'
import { notify } from '../../../actions/notifications'
import { getUser } from '../../../api/user'
import YousignSdk from './yousign-sdk'

import '../AdressPage/editAddressModal.sass'
import './yousign-iframe.sass'

class AnnualOfferSignatureModal extends Component {
  constructor(props) {
    super(props)

    this.yousignListenerFn = this.yousignListenerFn.bind(this)
  }

  componentDidMount() {
    const Yousign = YousignSdk()

    const yousign = new Yousign({
      signatureLink: this.props.signature.signature_url,
      iframeContainerId: 'yousign-iframe-container',
      isSandbox: GTM_ENV === 'staging',
    })

    window.addEventListener('message', this.yousignListenerFn)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.yousignListenerFn)
  }

  yousignListenerFn(e) {
    const { dispatch, signature, currentUser, close } = this.props

    if (e.data?.type === 'yousign' && e.data?.event === 'success') {
      fetch(`/api/user/${currentUser.id}/annual-contract-signed`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          signatureId: signature.signature_id,
          documentId: signature.document_id,
        }),
      })
        .then(res => res.json())
        .then(() => {
          getUser(() => {
            close()

            dispatch(notify('success', 'Contrat sauvegardé avec succès.'))
          })
        })
        .catch(() => dispatch(notify('error', 'Une erreur est survenue')))
    }
  }

  render() {
    return (
      <div id="yousign-iframe-container" />
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})


export default connect(MapStateToProps)(AnnualOfferSignatureModal)
