import axiosClient from './axios-client'

export function getUser(callback) {
  return fetch('/api/user', {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then((res) => {
      if (res.status !== 200) throw new Error('getUser error')
      return res
    })
    .then((res) => res.json())
    .then((res) => {
      callback(res)
    })
    .catch((e) => {
      console.log('ERROR : ', e)
      callback(null)
    })
}

export const getUserV2 = async () => axiosClient.get<User>('/api/user')
