import React, { Component } from 'react'
import { connect } from 'react-redux'

import { notify } from 'Actions/notifications'
import { openModal } from 'Actions/ui'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'
import CustomerAreaTitle from '../Display/CustomerAreaTitle'
import Button from '../../ui/Button'

import './contract.sass'

class ContractPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contractUrl: null,
      kbisUrl: null,
      prefectoralAgreementUrl: null,
      annualContractUrl: null,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    fetch(`/api/user/documents-download-urls`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          contractUrl: res.contractUrl,
          kbisUrl: res.kbisUrl,
          prefectoralAgreementUrl: res.prefectoralAgreementUrl,
          annualContractUrl: res.annualContractUrl,
        })
      })
      .catch(() => dispatch(notify('error', 'Une erreur est survenue')))
  }
  
  handleDownloadProcuration() {
    fetch(`/api/user/procuration-download`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => res.blob())
      .then(blob => {
        const data = window.URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.setAttribute('type', 'hidden')
        link.href = data
        link.download = 'procuration.pdf'
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
  }

  renderContractDownload() {
    const { contractUrl } = this.state

    if (!contractUrl) return null // TODO Loader
    const { currentUser } = this.props

    if (currentUser.netheos_status !== 'ARCHIVE') {
      return (
        <>
          <CustomerAreaTitle
            label="Mon contrat / attestation de domiciliation"
            icon="ic-mon-contrat"
          />
          <div className="contract-content">
            Votre dossier est en cours d'archivage. Votre contrat sera disponible d'ici quelques
            dizaines de minutes.
          </div>
        </>
      )
    }

    return (
      <>
        <CustomerAreaTitle
          label="Mon contrat / attestation de domiciliation"
          icon="ic-mon-contrat"
        />
        <div className="contract-content">
          <a href={contractUrl}>
            <Button label="Télécharger" secondaryClass="primary" />
          </a>
        </div>
      </>
    )
  }

  renderAnnualContractDownload() {
    const { annualContractUrl } = this.state
    if (!annualContractUrl) return null

    return (
      <div className="mt-8">
        <CustomerAreaTitle
          label="Contrat annuel"
          icon="ic-mon-contrat"
        />
        <div className="contract-content">
          <a href={annualContractUrl}>
            <Button label="Télécharger" secondaryClass="primary" />
          </a>
        </div>
      </div>
    )
  }

  renderProcurationDownload() {
    const { contractUrl } = this.state
    const { currentUser, dispatch } = this.props
    if (!currentUser || !contractUrl) return null // wait until other download urls are fetched, we don't want to render this part alone
    
    if (!currentUser.siren) {
      return (
        <>
          <CustomerAreaTitle label="Ma procuration" icon="ic-mon-contrat" />
          <p className="contract-description">
            Procuration non disponible, merci de communiquer votre SIREN dans votre espace client ou bien par email (contact@ma-domiciliation.fr).
          </p>
        </>
      )
    }

    if (currentUser.procuration === 'OUI') {
      return (
        <>
          <CustomerAreaTitle label="Ma procuration" icon="ic-mon-contrat" />
          <p className="contract-description">
            Votre procuration postale a été validée, vous pouvez la trouver dans vos courriers
            scannés (Boite aux lettres). Attention : par défaut, seuls les courriers des 30
            derniers jours sont affichés. Vous pouvez afficher plus d'historique en faisant varier
            le calendrier en haut à droite.
          </p>
        </>
      )
    }
    
    if (currentUser.procuration === 'Non nécessaire') {
      return (
        <>
          <CustomerAreaTitle label="Ma procuration" icon="ic-mon-contrat" />
          <p className="contract-description">
            Pour les boîtes postales simples (sans que votre siège soit à notre adresse de domiciliation), il n’est pas possible d’établir une procuration postale.
          </p>
        </>
      )
    }

    return (
      <>
        <CustomerAreaTitle label="Ma procuration" icon="ic-mon-contrat" />
        <p className="contract-description">Procuration non validée</p>
        <div className="contract-content">
          <Button
            label="Lire les instructions"
            secondaryClass="primary btn-marg-bottom"
            onClick={() => dispatch(openModal('procuration', {}))}
          />
          <Button
            label="Télécharger ma procuration à signer"
            secondaryClass="primary"
            onClick={() => this.handleDownloadProcuration()}
          />
        </div>
      </>
    )
  }
  renderOtherDownloads() {
    const { kbisUrl, prefectoralAgreementUrl } = this.state

    if (!kbisUrl || !prefectoralAgreementUrl) return null // TODO Loader

    return (
      <>
        <CustomerAreaTitle label="Kbis de la société de domiciliation" icon="ic-mon-contrat" />
        <div className="contract-description">
          Ce document vous sera demandé lors de vos formalités administratives de transfert de siège
          ou de création de société. Il est également appelé "Extrait du RCS du domiciliataire".
        </div>
        <div className="contract-content">
          <a href={kbisUrl}>
            <Button label="Télécharger" secondaryClass="primary" />
          </a>
        </div>
        <CustomerAreaTitle
          label="Agrément préfectoral de la société de domiciliation"
          icon="ic-mon-contrat"
        />
        <div className="contract-description">
          Ce document vous sera demandé lors de vos formalités administratives de transfert de siège
          ou de création de société.
        </div>
        <div className="contract-content">
          <a href={prefectoralAgreementUrl}>
            <Button label="Télécharger" secondaryClass="primary" />
          </a>
        </div>
      </>
    )
  }

  render() {
    return (
      <CustomerNavigation currentPage="contrat">
        <div className="customer-area-content-mobile-container">
          {this.renderContractDownload()}
          {this.renderOtherDownloads()}
          {this.renderProcurationDownload()}
          {this.renderAnnualContractDownload()}
        </div>
      </CustomerNavigation>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(ContractPage)
