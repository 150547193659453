import { configureStore } from '@reduxjs/toolkit'
import onboarding from './reducers/onboarding'

import stepper from '../reducers/stepper'
import addresses from '../reducers/addresses'
import subscription from '../reducers/subscription'
import user from '../reducers/user'
import ui from '../reducers/ui'
import notification from '../reducers/notification'
import products from '../reducers/products'

export const store = configureStore({
  reducer: {
    onboarding,
    stepper,
    addresses,
    products,
    subscription,
    user,
    ui,
    notification,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
