import { get, find } from 'lodash'

export const checkAddress = data => {
  const { address_components, types } = data

  if (!(types.length && ['street_address', 'route', 'subpremise', 'premise'].includes(types[0]))) {
    return 'Cela ne semble pas être une adresse postale complète, merci de vérifier.'
  }

  const country = find(address_components, comp => comp.types.length && comp.types[0] === 'country')

  if (!country) return 'Addresse imprécise, merci de vérifier votre saisie'

  if (country.long_name !== 'France') {
    return 'Merci de sélectionner une adresse en France'
  }
}

export const formatAddress = data => {
  const extract = name =>
    get(
      find(data.address_components, comp => comp.types[0] === name),
      'long_name',
    ) || ''

  return {
    formattedAddress: data.formatted_address,
    // premise: extract('subpremise'), // is now handled separately, keep for reference
    street: `${extract('street_number')} ${extract('route')}`,
    cp: extract('postal_code'),
    city: extract('locality'),
    country: `${extract('country')}`,
  }
}
