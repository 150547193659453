import React, { Component } from 'react'
import { connect } from 'react-redux'

import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import { notify } from '../../../actions/notifications'
import Dropzone from 'Ui/Dropzone'
import { getUser } from '../../../api/user'
import { setCurrentUser } from 'Actions/user'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AdressPage/editAddressModal.sass'

const initialState = {
  document: {
    file: null,
    error: null,
  },
  isDocumentSubmitting: false,
}

class StatutsModal extends Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  handleSubmitDocument() {
    const { close, currentUser, dispatch } = this.props
    const { document } = this.state
    if (!document.file) return

    const formData = new FormData()
    formData.append('status_file', document.file)
    this.setState({ isDocumentSubmitting: true })

    fetch(`/api/user/${currentUser.id}/statuts`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)
        return res
      })
      .then(res => {
        this.setState({ isDocumentSubmitting: false })
        close()
        dispatch(notify('success', 'Document enregistré avec succès.'))
        // refresh user data
        getUser(res => {
          dispatch(setCurrentUser(res))
        })
      })
      .catch((e) => {
        console.log(e)
        this.setState(initialState)
        dispatch(notify('error', 'Le document est impossible à analyser, merci de l’adresser par email à contact@ma-domiciliation.fr', 10000))
      })
  }

  renderDocumentDropzone() {
    const file = this.state.document.file

    if (file)
      return (
        <div className="selected-file">
          <img
            width="20"
            height="20"
            className="checkmarck-icon"
            src="/assets/icons/ic-checkmark-green.svg"
            alt="Validé"
          />
          <span>Fichier sélectionné : {file.name}</span>
        </div>
      )

    const onDrop = files => {
      if (files.length > 1) {
        this.setState({
          document: { error: 'Veuillez sélectionner un seul fichier' },
        })

        return
      }

      if (files[0].size >= 20971520) {
        this.setState({
          document: { error: 'Ce fichier est trop volumineux (20Mo maximum).' },
        })

        return
      }

      this.setState({
        document: { file: files[0] },
      })
    }

    return <Dropzone onDrop={onDrop} />
  }

  renderDocumentQuestion() {
    const { isDocumentSubmitting, document } = this.state

    return (
      <div className="form-block">
        <div className="label-form">
          <span className="step-subtitle">Envoi de vos Statuts</span>
        </div>
        <div className="ppe-text mb-20">
          Merci de nous faire parvenir vos statuts, avec notre adresse de domiciliation en tant que siège social.
        </div>
        <div className="mb-20">
          {this.renderDocumentDropzone()}
        </div>
        {document.error && (
          <div className="error">
            <p>{document.error}</p>
          </div>
        )}
        <div className="right-container mb-32">
          <SubmitSpinnerButton isSubmitting={isDocumentSubmitting}>
            <button className="btn valid small-height" onClick={() => this.handleSubmitDocument()}>
              Valider
            </button>
          </SubmitSpinnerButton>
        </div>
      </div>
    )
  }

  render() {
    const { unclosable } = this.props
    
    return (
      <div className="AmlModal">
        {this.renderDocumentQuestion()}
        {!unclosable && (
          <div className="mt-32 center-container">
            <button className="btn valid small-height close-btn" onClick={() => this.props.close()}>
              Plus tard
            </button>
          </div>
        )}
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(StatutsModal)
