/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { NOTIFY, FADE_NOTIFICATION } from '../actions/index'

type NotificationInitialState = {
  type: null | string,
  message: string,
  fadeOut: null | boolean,
  opened: boolean,
}

const initialState: NotificationInitialState = {
  type: null,
  message: '',
  fadeOut: null,
  opened: false,
}

const notification = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case NOTIFY:
      return {
        type: action.notifyType,
        message: action.message,
        fadeOut: action.fadeOut,
        opened: true,
      }
    case FADE_NOTIFICATION:
      return {
        ...state,
        opened: false,
      }
    default:
      return state
  }
}

export default notification
