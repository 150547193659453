/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { SET_CURRENT_USER } from '../actions/index'

const initialState = {
  currentUser: {
    contracts: [],
    customer_id: '',
    email: '',
    expedition_city: '',
    expedition_country: '',
    expedition_cp: '',
    expedition_premise: '',
    expedition_street: '',
    is_transfer: 'true',
    juridic_form: '',
    representant: {
      address: '',
      address2: '',
      city: '',
      country: '',
      cp: '',
      created_at: '',
      email: '',
      firstname: '',
      gender: '',
      id: 0,
      name: '',
      qualite: '',
      tel: '',
      updated_at: '',
      user_id: 0,
    },
    siren: '',
    social_denomination: '',
    tel: '',
    yousignPendingSignatures: [],
  },
}

const users = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const { user } = action

      return {
        ...state,
        currentUser: user,
      }
    }

    default:
      return state
  }
}

export default users
