export const parseQueryParams = queryString => {
  if (!queryString) return {}
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }

  return query
}

export const stringifyQueryParams = queryParams => {
  if (!Object.keys(queryParams).length) return ''
  const queryStrings = Object.keys(queryParams)
    .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&')

  return `?${queryStrings}`
}
