export const CHANGE_STEP = 'CHANGE_STEP'

export const RECEIVE_ADDRESSES = 'RECEIVE_ADDRESSES'
export const SET_ADRESSES_LOADING = 'SET_ADRESSES_LOADING'

export const FIELD_CHANGE = 'FIELD_CHANGE'
export const SAVE_STEP = 'SAVE_STEP'
export const RECOVER_FORM = 'RECOVER_FORM'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const NOTIFY = 'NOTIFY'
export const FADE_NOTIFICATION = 'FADE_NOTIFICATION'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
