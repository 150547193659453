/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { FETCH_PRODUCTS } from '../actions/index'

const initialState: Product[] = []

const products = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return [...action.products]

    default:
      return state
  }
}

export default products
