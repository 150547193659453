import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import Tooltip from 'rc-tooltip'

import handleValidate from 'Helpers/validators'
import { GTM_ENV } from 'Config/constants'
import AddressGeocode from 'Ui/AddressGeocode'
import Radio from 'Ui/Radio'
import { closeModal } from '../../../actions/ui'
import { notify } from '../../../actions/notifications'
import { setCurrentUser } from '../../../actions/user'
import { getUser } from '../../../api/user'
import YousignSdk from './yousign-sdk'
import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'

import 'rc-tooltip/assets/bootstrap.css'
import 'Ui/tooltip.sass'
import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AdressPage/editAddressModal.sass'
import './yousign-iframe.sass'

const expertFieldsValidators = {
  expertName: ['required'],
  expertAddress: ['required'],
}

class AttestationModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activityLocation: props.currentUser.activity_location || '',
      signatureUrl: null,
      signatureId: null,
      documentId: null,
    }

    this.yousignListenerFn = this.yousignListenerFn.bind(this)
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.signatureUrl && !prevState.signatureUrl) {
      const Yousign = YousignSdk()

      const yousign = new Yousign({
        signatureLink: this.state.signatureUrl,
        iframeContainerId: 'yousign-iframe-container',
        isSandbox: GTM_ENV === 'staging',
      })

      window.addEventListener('message', this.yousignListenerFn)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.yousignListenerFn)
  }

  yousignListenerFn(e) {
    const { dispatch } = this.props

    if (e.data?.type === 'yousign' && e.data?.event === 'success') {
      fetch(`/api/user/${this.props.currentUser.id}/attestation-signed`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          signatureId: this.state.signatureId,
          documentId: this.state.documentId,
        }),
      })
        .then(res => res.json())
        .then(() => {
          getUser(() => {
            this.props.close()

            dispatch(notify('success', 'Attestation sauvegardée avec succès.'))
          })
        })
        .catch(() => dispatch(notify('error', 'Une erreur est survenue')))
    }
  }



  handleSubmit(values) {
    const { dispatch, currentUser } = this.props

    fetch(`/api/user/${currentUser.id}/attestation-data`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        ...values,
        activityLocation: values.hasActivityLocation ? values.activityLocation : ''
      }),
    })
      .then(res => {
        if (res.status !== 200) throw new Error()
        return res.json()
      })
      .then(res => {
        dispatch(setCurrentUser(res.user))
        this.setState({
          signatureUrl: res.signatureUrl,
          signatureId: res.signatureId,
          documentId: res.documentId,
        })

        dispatch(notify('success', 'Données enregistrées.'))
      })
      .catch(() => dispatch(notify('error', 'Une erreur est survenue, veuillez réessayer puis contacter notre service client')))
  }

  activityLocationValidation(activityLocation) {
    if (!activityLocation) {
      return { activityLocation: 'Merci d’indiquer un lieu d’activité' }
    }

    if (!this.state.activityLocation) {
      return { activityLocation: 'Adresse imprécise.' }
    }

    if (activityLocation !== this.state.activityLocation) {
      return {
        activityLocation: 'Adresse non valide',
      }
    }

    return {}
  }

  handleValidation(values) {
    const expertError =
      values.hasExpert ? handleValidate(expertFieldsValidators)(values) : {}

    const activityLocationError =
      values.hasActivityLocation ? this.activityLocationValidation(values.activityLocation) : {}

    return {
      ...activityLocationError,
      ...expertError,
    }
  }


  setHasExpert(value, setFieldValue) {
    setFieldValue('hasExpert', value)

    if (!value) {
      setFieldValue('expertName', '')
      setFieldValue('expertAddress', '')
    }
  }

  setHasActivityLocation(value, setFieldValue) {
    setFieldValue('hasActivityLocation', value)

    if (!value) {
      setFieldValue('activityLocation', '')
      this.setState({ activityLocation: '' })
    }
  }

  handleSelectActivityLocation(address, setFieldValue) {
    console.log('address --------------------------------->')
    console.log(address)
    this.setState({ activityLocation: address.formattedAddress }, () => {
      setFieldValue('activityLocation', address.formattedAddress)
    })
  }

  renderTooltip() {
    const tooltipText = (
      <div className="Tooltip">
        Nous avons l’obligation légale de connaitre le nom de votre cabinet comptable. Si vous n’en
        avez pas, vous serez vous-même désigné comme comptable.
      </div>
    )

    return (
      <Tooltip placement="top" trigger={['click']} overlay={tooltipText}>
        <span className="Tooltip-trigger">?</span>
      </Tooltip>
    )
  }

  renderYousignIframe() {
    return (
      <div id="yousign-iframe-container" />
    )
  }

  render() {
    if (this.state.signatureUrl) return this.renderYousignIframe()

    const { currentUser, formOnly, fullWidth, unclosable } = this.props

    return (
      <div className={`edit-address-modal ${fullWidth ? 'full-width' : ''}`}>
        {!formOnly && (
          <div className="edit-address-title">Merci de renseigner les champs suivants</div>
        )}
        <Formik
          initialValues={{
            hasExpert: currentUser.has_expert || false,
            expertName: currentUser.expert_name || '',
            expertAddress: currentUser.expert_address || '',
            hasActivityLocation: currentUser.has_activity_location || false,
            activityLocation: currentUser.activity_location || '',
          }}
          validate={this.handleValidation.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ setFieldValue, setFieldError, values, errors, touched, isSubmitting }) => {
            return (
              <Form>
                <div className="form-block">
                  <div className="label-form row">
                    <span className="step-subtitle lh22">Votre cabinet d'expert comptable</span>
                    {this.renderTooltip()}
                  </div>
                  <div className="radio-list">
                    <label>
                      <Radio
                        type="radio"
                        name="hasExpert"
                        value="false"
                        onChange={() => this.setHasExpert(false, setFieldValue)}
                        checked={!values.hasExpert}
                      />
                      <span>Je n'ai pas d'expert comptable</span>
                    </label>
                    <label>
                      <Radio
                        type="radio"
                        name="hasExpert"
                        value="true"
                        onChange={() => this.setHasExpert(true, setFieldValue)}
                        checked={!!values.hasExpert}
                      />
                      <span>J'ai un expert comptable :</span>
                    </label>
                  </div>
                  <div className="inputs-block">
                    <div className="input">
                      <Field
                        name="expertName"
                        type="text"
                        className="Input-group"
                        placeholder="Nom du cabinet comptable"
                        onChange={e => {
                          setFieldValue('expertName', e.target.value)
                          if (values.hasExpert === 'false') {
                            setFieldValue('hasExpert', 'true')
                          }
                        }}
                      />
                      <span className="input-error-message">
                        {(values.hasExpert && touched.expertName && errors.expertName) ||
                          ''}
                      </span>
                    </div>
                    <div className="input">
                      <Field
                        name="expertAddress"
                        type="text"
                        className="Input-group"
                        placeholder="Adresse du cabinet comptable"
                        onChange={e => {
                          setFieldValue('expertAddress', e.target.value)
                          if (values.hasExpert === 'false') {
                            setFieldValue('hasExpert', 'true')
                          }
                        }}
                      />
                      <span className="input-error-message">
                        {(values.hasExpert &&
                          touched.expertAddress &&
                          errors.expertAddress) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="spacer-20" />
                </div>

                <div className="form-block">
                  <div className="label-form">
                    <span className="step-subtitle lh22">Lieu d’activité : exercez-vous votre activité à une adresse précise et fixe ? (Exemple : votre domicile personnel, vos bureaux, votre boutique…)</span>
                  </div>
                  <div className="radio-list">
                    <label>
                      <Radio
                        type="radio"
                        name="hasActivityLocation"
                        value="false"
                        onChange={() => this.setHasActivityLocation(false, setFieldValue)}
                        checked={!values.hasActivityLocation}
                      />
                      <span>Non</span>
                    </label>
                    <label>
                      <Radio
                        type="radio"
                        name="hasActivityLocation"
                        value="true"
                        onChange={() => this.setHasActivityLocation(true, setFieldValue)}
                        checked={values.hasActivityLocation}
                      />
                      <span>Oui :</span>
                    </label>
                  </div>
                  <div className="inputs-block">
                    <div className="input">
                      <Field
                        name="activityLocation"
                        component={AddressGeocode}
                        className="Input-group input"
                        placeholder="Adresse du lieu d'activité"
                        onChange={value => {
                          setFieldValue('activityLocation', value)
                          if (!values.hasActivityLocation) {
                            setFieldValue('hasActivityLocation', true)
                          }
                        }}
                        onSelect={address => this.handleSelectActivityLocation(address, setFieldValue)}
                        onError={error => setFieldError('activityLocation', error)}
                      />
                      <span className="input-error-message">
                        {(values.hasActivityLocation && touched.activityLocation && errors.activityLocation) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="spacer-20" />
                </div>

                <div className="edit-address-button-line">
                  {!unclosable ? (
                    <button className="btn valid close-btn" onClick={() => this.props.close()}>
                      Plus tard
                    </button>
                  ) : null}
                  <SubmitSpinnerButton isSubmitting={isSubmitting}>
                    <button type="submit" className="btn valid connexion-btn">
                      Valider
                    </button>
                  </SubmitSpinnerButton>
                </div>
              </Form>
            )
          }}
        />
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})


export default connect(MapStateToProps)(AttestationModal)
