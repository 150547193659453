import React, { Component } from 'react'
import { connect } from 'react-redux'

import axiosClient from '@api/axios-client'
import { StripeProvider } from 'react-stripe-elements'
import { STRIPE_PUBLIC_KEY, WEBSITE_URL } from 'Config/constants'
import StripePaymentElement from '@components/StripePaymentElement'

import '../PaymentMeansPage/PaymentMeans.sass'

class RenvoiUrgentPaymentModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isValidating: false,
      clientSecret: null,
    }
  }

  async componentDidMount() {
    const res = await axiosClient.get('/api/user/paymentIntent?source_account=true')
    this.setState({
      clientSecret: res.data.clientSecret
    })
  }

  async handleCbSubmit({ paymentMethodId }) {
    const { close, onValidate, closeAfterValidate } = this.props
    onValidate(paymentMethodId)
    if (close && closeAfterValidate) close()
  }

  render() {
    const { title } = this.props
    const { clientSecret } = this.state
    if (!clientSecret) return null

    return (
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
        <div className="means-modal">
          {title || null}
          <StripePaymentElement
            clientSecret={clientSecret}
            isOffSession={true}
            onPaymentMeanSuccess={this.handleCbSubmit.bind(this)}
            returnUrl={`${WEBSITE_URL}/mon-compte/paiement`}
          />
        </div>
      </StripeProvider>
    )
  }
}


const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(RenvoiUrgentPaymentModal)
