const SATISMETER_APP_KEY = '56t9S43p3Ef1dX81'

export default user => {
  // eslint-disable-next-line no-unexpected-multiline, no-extra-semi
  ;(function() {
    window.satismeter =
      window.satismeter ||
      function() {
        // eslint-disable-next-line no-extra-semi
        ;(window.satismeter.q = window.satismeter.q || []).push(arguments)
      }
    window.satismeter.l = 1 * new Date()
    var script = document.createElement('script')
    var parent = document.getElementsByTagName('script')[0].parentNode
    script.async = 1
    script.src = 'https://app.satismeter.com/js'
    parent.appendChild(script)
  })()

  window.satismeter({
    writeKey: SATISMETER_APP_KEY,
    userId: user.id,
    traits: {
      email: user.email,
      createdAt: user.created_at,
    },
  })
}
