import React, { Component } from 'react'
import { NETHEOS_SIGN_URL } from 'Config/constants'

import './netheos-wrapper.sass'

var MESSAGE_TITLES = {
  EXIT: 'A bientôt !',
  SUSPENDED: 'Pièces justificatives en cours de contrôle',
  FINALIZED: 'Dossier complet',
  WAITING: 'Dossier en cours de validation',
  ACCEPTED: 'Félicitations !',
  REJECTED: 'Désolé',
}

var MESSAGE_TEXTS = {
  EXIT: 'Vous pouvez revenir quand vous le souhaitez compléter votre dossier.',
  SUSPENDED:
    "Une ou plusieurs des pièces que vous avez soumises sont en cours d'examen par nos opérateurs. Merci de revenir plus tard.",
  FINALIZED:
    "Votre dossier est désormais complet, nous vous recontacterons quand nous l'aurons examiné.",
  WAITING: "Votre dossier est en cours d'examen.",
  ACCEPTED: 'Votre dossier a été accepté.',
  REJECTED: 'Votre dossier a été refusé.',
}

export default class NetheosWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIdDocumentType: null,
      isLoadingAddDocumentRequest: false,
      isSelectingAdditionnalDocument: false,
    }
  }

  componentDidMount() {
    const { token } = this.props

    // if script was already in dom, remove it so that it can re-run
    const existingScript = document.getElementById('signbook-script')
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript)
    }

    const script = document.createElement('script')

    script.src = `${NETHEOS_SIGN_URL}/signbook/v3/script/signbook.js`
    script.id = 'signbook-script'
    script.async = true
    document.body.appendChild(script)

    document.getElementById('signbook-script').addEventListener('load', () => {
      this.signbook = new window.NthSignbook({
        iframeSelectorId: 'signbook',
        url: `${NETHEOS_SIGN_URL}/signbook/signbook.html`,
        options: {
          renderMode: 'pretty',
        },
        onClientFileEventMessage: this.iframeListeners.bind(this, 'clientFileEvent'),
        onDisplayEventMessage: this.iframeListeners.bind(this, 'displayEvent'),
        onUserEventMessage: this.iframeListeners.bind(this, 'userEvent'),
        token,
      })
    })
  }

  iframeListeners(type, event) {
    const { onValidated } = this.props

    if (type === 'displayEvent') {
      // Adjust iframe size function of how big the SignBook want to be
      if (event.cause === 'heightChanged') {
        const signbook = document.getElementById('signbook')
        if (!signbook) return

        const currentHeight = signbook.style.height.split('px')[0]
        const newHeight = event.height + 20

        // auto resize Iframe depending on content
        // Check that it's not lower than min-height and if there is a significant difference between heights to avoid page flicks
        if (newHeight > 730 && Math.abs(currentHeight - newHeight) > 20) {
          signbook.style.height = newHeight + 'px'
        }
      } else if (event.cause === 'pageChanged') {
        // scroll up if changing into justificatifs page
        window.scrollTo(0, 0)
      }
    }

    if (type === 'clientFileEvent') {
      if (this.state.selectedIdDocumentType === 'RESIDENCE_PERMIT'
        && event.context.nextStep === 'SIGNATURE'
        && event.context.signatureState === 'TODO'
      ) {
        this.setState({
          isLoadingAddDocumentRequest: true,
        })

        this.askForAnotherIdDocument(() => {
          this.setState({
            isLoadingAddDocumentRequest: false,
            isSelectingAdditionnalDocument: true,
          }, () => this.componentDidMount())
        })
        return
      }

      if (this.state.selectedIdDocumentType === 'RESIDENCE_PERMIT'
        && event.changedState
        && event.changedState.from === 'PENDING'
        && event.changedState.to === 'SUSPENDED'
      ) {
        // means a document is pending validation, so we need to add document request but not refresh signbook right now
        this.askForAnotherIdDocument()
        return
      }

      if (event.action === 'SIGN') {
        window.dataLayer.push({
          event: 'domEvent',
          eventCat: 'Tunnel',
          eventAct: 'Étape 5.3 validée',
        })
      }

      // Event coming from eKeynox SaaS with data concerning the state of the client file
      console.log('client file state : ' + event.state)

      if (
        (event.changedState &&
          event.changedState.from === 'PENDING' &&
          (event.changedState.to === 'WAITING' || event.changedState.to === 'ACCEPTED')) ||
        event.participantState === 'WAITING' ||
        event.state === 'ACCEPTED'
      ) {
        onValidated()
        console.log(MESSAGE_TITLES['FINALIZED'], MESSAGE_TEXTS['FINALIZED'])

        window.dataLayer.push({
          event: 'domEvent',
          eventCat: 'Tunnel',
          eventAct: 'Étape 5.4 validée',
        })
      } else {
        if (event.state !== 'PENDING') {
          console.log(MESSAGE_TITLES[event.state], MESSAGE_TEXTS[event.state])
        }
      }
    }

    if (type === 'userEvent'
      && ['SELECT_DOCUMENT_TYPE', 'SELECT_FILE'].includes(event.action)
      && ['attachment_1', 'attachment_2'].includes(event.context.attachmentId)
    ) {
      this.setState({
        selectedIdDocumentType: event.context.documentType,
      })
    }
  }

  askForAnotherIdDocument(callback) {
    const existingScript = document.getElementById('signbook-script')
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript)
    }

    this.props.addIdDocumentRequest(callback)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.iframeListen)
  }

  render() {
    console.log('this.state --------------------------------->')
    console.log(this.state)
    const { isSelectingAdditionnalDocument, isLoadingAddDocumentRequest, selectedIdDocumentType } = this.state
    if (isLoadingAddDocumentRequest) {
      return <div>Analyse des documents...</div>
    }

    return (
      <div className="NetheosWrapper">
        {isSelectingAdditionnalDocument && selectedIdDocumentType === 'RESIDENCE_PERMIT' && 
          <div className='mb-20'>Merci d'ajouter votre passeport ou pièce d'identité en bas de page</div>
        }
        <iframe
          id="signbook"
          scrolling="no"
          frameBorder="no"
          width="100%"
          allow="microphone; camera"
        />
      </div>
    )
  }
}
