import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import { closeModal } from 'Actions/ui'

import './procuration-modal.sass'

class ProcurationModal extends Component {
  render() {
    const { unclosable, dispatch } = this.props

    return (
      <div className="ProcurationModal">
        <img
          onClick={() => dispatch(closeModal())}
          src="/assets/icons/icon-cross.svg"
          className="notif-modal-close"
          alt="fermer"
        />
        <div className="">
          <h3>Pourquoi établir une procuration postale&nbsp;?</h3>
          <ul>
            <li>
              Afin que nous puissions réceptionner et signer vos courriers recommandés en votre nom
            </li>
            <li>
              Cette procuration postale vous sera également demandée par le service des impôts des
              entreprises (SIE), par exemple pour obtenir votre numéro de TVA
            </li>
          </ul>
          <h3>Quand établir la procuration&nbsp;?</h3>
          <p>
            Pour établir la procuration, il faut transmettre à la Poste (voir ci-dessous) votre Kbis
            avec notre adresse de domiciliation en tant que siège. Tant que vous n’avez pas ce Kbis,
            il n’est pas utile d’entamer les démarches de procuration.
          </p>
          <h3>Comment établir la procuration&nbsp;?</h3>
          <p>
            Afin d'établir une procuration postale, merci d’envoyer directement au service “Dossiers
            de société” de la Poste (adresse ci-dessous) les 3 documents suivants :
            <ul>
              <li>
                votre Kbis (ou votre SIRENE si vous êtes une micro-entreprise) avec l'adresse de
                domiciliation en tant que siège
              </li>
              <li>
                la procuration complétée et signée : Vous trouverez une version déjà complétée dans
                votre espace client, il vous suffira simplement de la signer
              </li>
              <li>la carte d’identité ou passeport du représentant légal de la société</li>
            </ul>
          </p>
          <p>
            Ces 3 documents sont à expédier à l’adresse suivante :
            <br />
            <span className="bold">
              C.N.G.D.C
              <br />
              Valérie Decuq
              <br />
              1, rue Pablo
              <br />
              Neruda 81032 ALBI cedex 9
            </span>
          </p>
          <p>
            Si vous n’avez pas d’imprimante ou ne souhaitez pas vous déplacer, nous vous
            recommandons d’utiliser le service{' '}
            <a href="http://merci-facteur.com/" className="blue-link">
              merci-facteur.com
            </a>{' '}
            qui imprime et poste vos courriers pour vous !
          </p>
          <h3>Quand la procuration sera-t-elle validée&nbsp;?</h3>
          <p>
            Les procurations sont validées par La Poste dans un délai de 2 semaines en moyenne. Dès
            que nous recevrons votre procuration validée, nous vous la transmettrons par email.
          </p>
        </div>
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const MapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
  dispatch,
})

export default connect(
  MapStateToProps,
  MapDispatchToProps,
)(ProcurationModal)
