import React, { Component } from 'react'
import { connect } from 'react-redux'
import { head, get } from 'lodash'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'
import CustomerAreaTitle from '../Display/CustomerAreaTitle'
import CustomerAreaInfoLine from '../Display/CustomerAreaInfoLine'

import { openModal } from 'Actions/ui'
import { notify } from 'Actions/notifications'
import { setCurrentUser } from 'Actions/user'
import { emailRegex } from 'Helpers/validators'

import '../Display/CustomerAreaDisplay.sass'
import 'Ui/input.sass'

class AdressPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditingEmail: false,
      emailInput: '',
      emailValidateError: null,
    }
  }

  render() {
    const { currentUser } = this.props

    return (
      <CustomerNavigation currentPage="adresse">
        <div className="customer-area-content-mobile-container">
          <CustomerAreaTitle label="Mon email de compte" />
        </div>
        <div className="customer-area-info-block">{this.renderEmailInfo(currentUser)}</div>
        <div className="customer-area-content-mobile-container">
          <CustomerAreaTitle label="Mon adresse de domiciliation" />
        </div>
        {this.renderDomiciliationInfo(currentUser)}
        <div className="customer-area-content-mobile-container">
          <CustomerAreaTitle label="Réexpédition de votre courrier" />
        </div>
        {this.renderReexpeditionInfo(currentUser)}
      </CustomerNavigation>
    )
  }

  renderDomiciliationInfo(currentUser) {
    const contract = head(currentUser.contracts)

    //if(!contract) { return null } // TODO Loader

    return (
      <div className="customer-area-info-block">
        <CustomerAreaInfoLine
          label="Adresse"
          value={contract && this.formatDomiciliationAddress(contract.address)}
        />
      </div>
    )
  }

  validateEmailChange() {
    const { dispatch, currentUser } = this.props
    const { emailInput } = this.state

    if (!emailRegex.test(emailInput)) {
      this.setState({ emailValidateError: 'Format de l’email incorrect' })

      return
    }

    fetch(`/api/user/${currentUser.id}/change-email`, {
      method: 'PUT',
      body: JSON.stringify({ newEmail: emailInput.trim().toLowerCase() }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        this.setState({ isEditingEmail: false })
        if (res.status === 400) {
          dispatch(notify('error', 'Un autre compte existe déjà pour cet email.'))
          
          return res
        }
        
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        if (res.result === 'ok') {
          dispatch(
            setCurrentUser({
              ...currentUser,
              email: emailInput,
            }),
          )
          dispatch(notify('success', 'Modification enregistrée.'))
        }
      })
      .catch(() => {
        dispatch(
          notify('error', 'Une erreur est survenue lors de la modification de l’adresse mail.'),
        )
      })
  }

  renderEmailInfo(currentUser) {
    const { emailInput, isEditingEmail, emailValidateError } = this.state

    if (isEditingEmail) {
      return (
        <CustomerAreaInfoLine
          label="Email"
          value={
            <div className="">
              <input
                className="CustomerArea-line-input"
                type="text"
                value={emailInput}
                onChange={e =>
                  this.setState({ emailInput: e.target.value, emailValidateError: null })
                }
              />
              {emailValidateError && (
                <div className="input-error-message">{emailValidateError}</div>
              )}
            </div>
          }
          onClick={() => this.validateEmailChange()}
          buttonLabel="Valider"
        />
      )
    }

    return (
      <CustomerAreaInfoLine
        label="Adresse"
        value={currentUser.email}
        onClick={() => this.setState({ isEditingEmail: true })}
      />
    )
  }

  renderReexpeditionInfo(currentUser) {
    //if(!currentUser) { return null } // TODO Loader

    return (
      <div className="customer-area-info-block">
        <CustomerAreaInfoLine
          label="Adresse"
          value={this.formatExpeditionAddress(currentUser)}
          onClick={() => this.editField()}
        />
        <CustomerAreaInfoLine
          label="Complément"
          value={get(currentUser, 'expedition_premise', '')}
          onClick={() => this.editField()}
        />
        <CustomerAreaInfoLine
          label="A l‘attention de :"
          value={get(currentUser, 'expedition_name', '')}
          onClick={() => this.editField()}
        />
      </div>
    )
  }

  formatDomiciliationAddress(source) {
    return `${get(source, 'address', '')},
            ${get(source, 'address_complement') ? get(source, 'address_complement', '') + ',' : ''}
            ${get(source, 'cp', '')}
            ${get(source, 'city', '')}`
  }

  formatExpeditionAddress(source) {
    return `${get(source, 'expedition_street', '')},
            ${get(source, 'expedition_cp', '')}
            ${get(source, 'expedition_city', '')}`
  }

  formatRepresentantIdentity(representant) {
    return `${get(representant, 'gender', '')}
            ${get(representant, 'firstname', '')}
            ${get(representant, 'name', '')}`
  }

  editField() {
    this.props.openAdressModal()
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const MapDispatchToProps = dispatch => ({
  openAdressModal: () => dispatch(openModal('editAddress', {})),
  dispatch,
})

export default connect(MapStateToProps, MapDispatchToProps)(AdressPage)
