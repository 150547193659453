import React, { Component } from 'react'

export default class SubmitSpinnerButton extends Component {
  render() {
    const { label, className, isSubmitting, children } = this.props

    if (isSubmitting) {
      return (
        <label className={`btn btn-nav payment-wait ${className}`}>
          <img height="20px" src="/assets/images/spinner.gif" alt="Veuillez patienter" />
        </label>
      )
    }

    return children
  }
}
