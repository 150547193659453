/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { OPEN_MODAL, CLOSE_MODAL } from '../actions/index'

type UiInitialState = {
  modalsQueue: {
    modalName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modalProps: any,
  }[]
}

const initialState: UiInitialState = {
  modalsQueue: [],
}

const stepper = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalsQueue: [
          ...state.modalsQueue,
          {
            modalName: action.modalName,
            modalProps: action.modalProps,
          },
        ],
      }
    case CLOSE_MODAL:
      return {
        ...state,
        modalsQueue: state.modalsQueue.slice(1),
      }
    default:
      return state
  }
}

export default stepper
