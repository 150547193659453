import React, { Component } from 'react'

import { connect } from 'react-redux'

import { openModal } from '../../../actions/ui'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'

import './PaymentMeans.sass'

class PaymentMeansPage extends Component {
  renderTaking() {
    const { openIbanModal, openCbModal, currentUser = {} } = this.props
    const { last4, sepa_ok, use_sepa } = currentUser
    const paymentMean = sepa_ok ? 'prélèvement' : 'carte bancaire'
    const openModal = use_sepa ? openIbanModal : openCbModal

    return (
      <div className="means-page">
        <div className="means-container">
          <div className="means-subtitle">Paiement par {paymentMean}</div>
          <div className="means-fields">
            <input
              name="number"
              type="text"
              className="Input-group"
              value={`XXXX XXXX XXXX ${last4}`}
              disabled
            />
          </div>
        </div>
        <div onClick={openModal} className="btn primary connexion-btn">
          Modifier
        </div>
      </div>
    )
  }

  render() {
    return (
      <CustomerNavigation currentPage="paiement">
        <div className="means-title">Moyen de paiement</div>
        {this.renderTaking()}
      </CustomerNavigation>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const MapDispatchToProps = dispatch => ({
  openCbModal: () => dispatch(openModal('editCB')),
  openIbanModal: () => dispatch(openModal('editIBAN')),
})

export default connect(MapStateToProps, MapDispatchToProps)(PaymentMeansPage)
