import React, { Component } from 'react'

import Button from '../../ui/Button'

export default class CustomerAreaInfoLine extends Component {
  render() {
    const { label, value, buttonLabel, onClick } = this.props

    return (
      <div className="customer-area-info-line">
        <div className="customer-area-info-left">
          <div className="customer-area-info-label">{label}</div>
          <div className="customer-area-info-value">{value}</div>
        </div>
        {onClick && (
          <div className="customer-area-edit-btn" onClick={onClick}>
            <Button label={buttonLabel || 'Modifier'} secondaryClass="primary" />
          </div>
        )}
      </div>
    )
  }
}
