import { OPEN_MODAL, CLOSE_MODAL } from './index'

export const openModal = (modalName: string, modalProps: unknown) => ({
  type: OPEN_MODAL,
  modalName,
  modalProps,
})

export const closeModal = () => ({
  type: CLOSE_MODAL,
})
