import React from 'react'

export const formatPrice = (price: number, withoutSpace = false) => {
  if (price === null) return ''
  if (price === 0) return 'Gratuit'

  return price
    ? `${price.toLocaleString('fr-fr', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${
      withoutSpace ? '' : ' '
    }€`.replace('.', ',')
    : ''
}

export const formatApiPrice = (amount: number, currency: string) => {
  const options = { style: 'currency', currency }
  const numberFormat = new Intl.NumberFormat('fr-FR', options)
  return numberFormat.format(amount / 100)
}

export const formatAddressPrice = (price: number) => {
  if (price === null) return ''
  if (price === 0) return 'Gratuit'
  const splitStringifyPrice = price
    .toLocaleString('fr-fr', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .split(',')

  return (
    <>
      {splitStringifyPrice[0]}
      €
      <sup>{splitStringifyPrice[1]}</sup>
    </>
  )
}
