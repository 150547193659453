import React, { Component } from 'react'

class MaintenancePage extends Component {
  render() {
    return (
      <div style={{ backgroundColor: '#97a3b6', height: '100vh', width: '100vw', padding: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '8px', maxWidth: '512px' }}>
          Bonjour,<br/>
          Nous effectuons actuellement une maintenance programmée du site.<br />
          Tous nos services seront à nouveau disponibles d'ici une à deux heures.<br />
          <br />
          <div style={{ textAlign: 'right' }}>L'équipe de ma-domiciliation.fr</div>
        </div>
        
      </div>
    )
  } 
}

export default MaintenancePage