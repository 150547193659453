import React from 'react'
import RDropzone from 'react-dropzone'

import './dropzone.sass'

const Dropzone = ({ onDrop }) => (
  <RDropzone onDrop={onDrop}>
    {({getRootProps, getInputProps}) => (
      <section>
        <div {...getRootProps()} className="Dropzone">
          <input {...getInputProps()} />
          <p>Déposez un fichier ici,  ou cliquer ici pour le sélectionner.</p>
        </div>
      </section>
    )}
  </RDropzone>
)

export default Dropzone
