export const MAPS_API_KEY = 'AIzaSyD_XdP2cSdVtI0P3rhDqNuGOPmYSRLGcaM'

export const CONTACT_MAIL = 'contact@ma-domiciliation.fr'
export const CONTACT_PHONE = '01 75 85 06 53'
export const DISABLE_CHAT = import.meta.env.VITE_DISABLE_CHAT
export const DISABLE_LOGROCKET = import.meta.env.VITE_DISABLE_LOGROCKET
export const GTM_ENV = import.meta.env.VITE_GTM_ENV
export const MAINTENANCE_MODE = import.meta.env.VITE_MAINTENANCE_MODE
export const NETHEOS_SIGN_URL = import.meta.env.VITE_NETHEOS_SIGN_URL
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY
export const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL

// In the past the stripe product metadata.key was related to
// engagement duration choosed by user
// Now there is no more choice for engagement, the product we use
// is the one that was for 24 month of engagement
export const DOMICILIATION_PRODUCT_KEY = '24m'
