import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
// import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import LogRocket from 'logrocket'
// import 'whatwg-fetch'
import { DISABLE_LOGROCKET } from 'Config/constants'
// import 'virtual:windi.css'
import * as Sentry from "@sentry/react";

import './constants/base.sass'
import './constants/fonts.sass'
import { SENTRY_DSN } from './config/constants'

window.global ||= window

if (!(DISABLE_LOGROCKET === 'true')) {
  LogRocket.init('dnunsn/ma-domiciliation', {
    network: {
      requestSanitizer: request => {
        if (
          request.url.toLowerCase().indexOf('api/login') !== -1
          || request.url.toLowerCase().indexOf('/password') !== -1
        ) {
          request.body = 'hidden'
        }

        return request
      },
    },
  })
}

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN });
}

ReactDOM.render(<App />, document.getElementById('root'))
// ReactDOM.createRoot(document.getElementById('root')).render(<App />)
