import React, { Component } from 'react'
import MainNav from '../layout/MainNav'

// inline style because placeholder page
class LazyLoadingPage extends Component {
  render() {
    return (
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <MainNav lightVersion />
        <div style={{ height: '100%', backgroundColor: '#fafbfe', flex: '1' }} />
      </div>
    )
  }
}

export default LazyLoadingPage
