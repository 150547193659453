import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { orderBy } from 'lodash'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'
import CustomerAreaTitle from '../Display/CustomerAreaTitle'
import InvoiceLine from './InvoiceLine'
import LetterBoxPagination from '../LetterBox/LetterBoxPagination'

import { notify } from '../../../actions/notifications'

const InvoicesPerPage = 10
class InvoicesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      invoices: [],
      currentPage: 0,
      totalPages: 1,
    }
  }

  componentDidMount() {
    const { currentUser } = this.props

    if (currentUser.id) this.fetchInvoices(currentUser.id)
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props

    if (!(currentUser.id && prevProps.currentUser.id !== currentUser.id)) return

    this.fetchInvoices(currentUser.id)
  }

  fetchInvoices(userId) {
    fetch(`/api/user/${userId}/invoices`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => res.json())
      .then(({ invoices, totalCount }) => {
        console.log('invoices ------------------------------------------>')
        console.log(invoices)

        const ordered = orderBy(invoices, 'created', ['desc'])

        this.setState({
          invoices: ordered,
          totalPages: Math.ceil(totalCount / InvoicesPerPage),
        })
      })
      .catch(() => this.props.dispatch(notify('error', 'Une erreur est survenue')))
  }

  downloadInvoice(id, customerId, date) {
    console.log('customerId ! ------------------------------------------>')
    console.log(customerId)
    const { currentUser, dispatch } = this.props

    fetch(`/api/user/${currentUser.id}/invoices/${id}/download`, {
      method: 'POST',
      body: JSON.stringify({ customerId }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => res.blob())
      .then(blob => {
        const formattedDate = moment.unix(date).format('DD_MM_YYYY')
        const data = window.URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.setAttribute('type', 'hidden')
        link.href = data
        link.download = `facture_${formattedDate}.pdf`
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(() => dispatch(notify('error', 'Une erreur est survenue')))
  }

  renderInvoices() {
    const { invoices, currentPage } = this.state

    const invoicesPerPage = invoices.slice(
      currentPage * InvoicesPerPage,
      (currentPage + 1) * InvoicesPerPage,
    )

    return invoicesPerPage.map(invoice => (
      <InvoiceLine
        key={invoice.id}
        date={invoice.created}
        price={invoice.total}
        download={this.downloadInvoice.bind(this, invoice.id, invoice.customer, invoice.created)}
      />
    ))
  }

  render() {
    const { totalPages, currentPage } = this.state

    return (
      <CustomerNavigation currentPage="factures">
        <div className="customer-area-content-mobile-container">
          <CustomerAreaTitle label="Mes factures" icon="ic-mes-factures" />
          <div className="invoice-content">{this.renderInvoices()}</div>
          <LetterBoxPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onSelectPage={page => this.setState({ currentPage: page })}
          />
        </div>
      </CustomerNavigation>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(InvoicesPage)
