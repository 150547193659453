import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'

class CustomerNavigationLink extends Component {
  render() {
    const { icon, label, route, focus, disabled } = this.props
    const hasFocus = focus === route
    const focusClass = hasFocus ? 'navigation-focus' : ''
    const disabledClass = disabled ? 'navigation-disabled' : ''
    const onClick = disabled ? e => e.preventDefault() : null

    return (
      <Link to={route} onClick={onClick}>
        {hasFocus && <div className="navigation-focus-border" />}
        <div className={`customer-navigation-link ${focusClass} ${disabledClass}`}>
          <div className="customer-navigation-icon">
            <img src={`/assets/icons/${icon}${hasFocus ? '-focus' : ''}.svg`} alt={label} />
          </div>
          <div>{label}</div>
        </div>
      </Link>
    )
  }
}

export default withRouter(CustomerNavigationLink)
