import React, { Component } from 'react'
import { Provider } from 'react-redux'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { store } from './store-v2'
// import { store as store2 } from './store-v2'
import Main from './Main'
import MaintenancePage from './components/MaintenancePage/MaintenancePage'
import { MAINTENANCE_MODE } from 'Config/constants'

const queryClient = new QueryClient()

class App extends Component {
  render() {
    if (MAINTENANCE_MODE === 'true') {
      return <MaintenancePage />
    }

    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Main />
        </Provider>
      </QueryClientProvider>
    )
  }
}

export default App
