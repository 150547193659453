import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import Button from '../ui/Button'
import { parseQueryParams } from 'Helpers/queryParams'

import './mainNav.sass'

class MainNav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      opened: false,
    }
  }

  toggleMenu() {
    this.setState({ opened: !this.state.opened })
  }

  analytics(act, lab) {
    const labObject = lab ? { eventLab: lab } : {}

    window.dataLayer.push({
      event: lab ? 'domEventLab' : 'domEvent',
      eventCat: 'Header',
      eventAct: act,
      ...labObject,
    })
  }

  renderLogo() {
    return (
      <Link to="/" className="logo-link" onClick={() => this.analytics('Logo')}>
        <div className="logo">
          <span className="logo-icon" />
          <p>ma-domiciliation.fr</p>
        </div>
      </Link>
    )
  }

  renderLinks() {
    return (
      <Fragment>
        <NavHashLink
          to="/#addresses-section"
          onClick={() => this.analytics('Interactions', 'Nos adresses')}
        >
          <span className="middle-button">Nos adresses</span>
        </NavHashLink>
        <Link to={'/contact'} onClick={() => this.analytics('Interactions', 'Besoin d’aide')}>
          <span className="middle-button">Besoin d’aide ?</span>
        </Link>
      </Fragment>
    )
  }

  renderDesktopLinks() {
    if (this.props.lightVersion) return null

    return <div className="desktop">{this.renderLinks()}</div>
  }

  getSubscriptionLink() {
    const { subscriptionLinkParams } = this.props
    const baseUrl = '/inscription/email'

    if (!subscriptionLinkParams) return baseUrl
    if (subscriptionLinkParams === '1euro') return `${baseUrl}?discount=1euro`

    if (subscriptionLinkParams === 'transfert') {
      const queryParams = parseQueryParams(window.location.search)
      const is1euro1M = queryParams.discount === '1euro1m'

      return `${baseUrl}?offer=transfert${is1euro1M ? '&discount=1euro1m' : ''}`
    }
  }

  renderRightLinks() {
    if (this.props.lightVersion) return null

    return (
      <div className="MainNav-links">
        <Link
          to={this.getSubscriptionLink()}
          onClick={() => this.analytics('Obtenir mon prix', 'MainNav')}
        >
          <Button secondaryClass="primary small-height sm-font" label="Obtenir mon prix" />
        </Link>
        <Link to="/login" onClick={() => this.analytics('Login')}>
          <Button secondaryClass="btn-nav sm-font border-b" label="SE CONNECTER" />
        </Link>
      </div>
    )
  }

  renderPhone() {
    return (
      <div className="phone">
        <div className="phone-icon" />
        <div>
          <a href="tel:0175850653">
            <p className="number">01 75 85 06 53</p>
          </a>
          <p className="number-info">Appel Gratuit</p>
        </div>
      </div>
    )
  }

  renderMenuMobile() {
    return (
      <div id="menu-mobile" className={this.state.opened ? '' : 'hidden'}>
        <div className="container-logo-close">
          <Link to={`/`} onClick={() => this.analytics('Logo')}>
            <div className="logo-light">
              <span className="logo-icon-light" />
              <p>ma-domiciliation.fr</p>
            </div>
          </Link>
          <div className="close-menu" onClick={() => this.toggleMenu()} />
        </div>
        <div className="menu-link">{this.renderLinks()}</div>
        <div className="action-nav-mobile">
          {this.renderPhone()}
          <Link
            className="btn btn-nav orange"
            to={this.getSubscriptionLink()}
            onClick={() => this.analytics('Obtenir mon prix')}
          >
            Obtenir mon prix
          </Link>
          <Link className="btn btn-nav" to="/login" onClick={() => this.analytics('Se domicilier')}>
            Se connecter
          </Link>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={`main-nav ${this.props.secondaryClass || ''}`}>
        <div className="nav-logo">
          <div className="drop-button" onClick={() => this.toggleMenu()} />
          {this.renderLogo()}
          {this.renderDesktopLinks()}
        </div>
        <div className="action-nav">
          {this.renderPhone()}
          {this.renderRightLinks()}
        </div>
        {this.renderMenuMobile()}
      </div>
    )
  }
}

export default MainNav
