/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { FIELD_CHANGE, SAVE_STEP, RECOVER_FORM } from '../actions/index'

const initialState = {
  step0: {
    email: '',
    tel: '',
  },
  step1: {
    addressId: '-1',
  },
  step2: {
    juridicForm: '0',
    socialDenomination: '',
    isTransfer: 'false',
    siren: '',
  },
  step3: {
    expeditionAddress: null,
    expeditionFrequency: 'scan',
  },
  step5: {
    email: '',
    password: '',
    pwdconfirm: '',
  },
  mandataire: {
    type: null,
    particulier_name: '',
    birthplace: '',
    birthdate: '',
    phone_number: '',
    representant_name: '',
    siren: '',
    social_denomination: '',
    juridic_form: '0',
    id_justif_file: null,
    mandate_file: null,
    kbis_file: null,
  },
  representant: {
    gender: '0',
    firstname: '',
    name: '',
    qualite: '0',
    address: '',
    address2: '',
    cp: '',
    city: '',
    country: 'FR',
    tel: '',
    email: '',
  },
  userId: null,
  netheosToken: null,
}

function makeid() {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 5; i += 1) {
    text += possible.charAt(
      Math.floor(
        Math.random() * possible.length,
      ),
    )
  }

  return text
}

// test values, to prefill subscription form replace "state = initialState" by this one
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initialStateTest = {
  step0: {
    email: `cedric+${makeid()}@ma-domiciliation.fr`,
    tel: '0612312312',
  },
  step1: {
    addressId: '2007',
  },
  step2: {
    juridicForm: 'SARL',
    socialDenomination: 'Dernier Cri',
    isTransfer: 'false',
    siren: '',
    // siren: '524671260',
  },
  step3: {
    expeditionAddress: {
      premise: 'premise',
      street: 'street',
      cp: 'cp',
      city: 'city',
      country: 'country',
    },
    expeditionFrequency: 'scan',
  },
  representant: {
    gender: 'MR',
    firstname: 'antoine',
    name: 'mesnil',
    qualite: 'gérant',
    address: '9 carriere lagache',
    address2: '',
    cp: '59910',
    city: 'Bondues',
    country: 'France',
    tel: '0612312312',
    email: 'cedric@derniercri.io',
  },
  step6: {
    email: '',
    password: 'test',
    pwdconfirm: 'test',
  },
  step7: {
    hasExpert: true,
    expertName: '',
    expertAddress: '',
  },
  userId: null,
  netheosToken: null,
}

const subscription = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case FIELD_CHANGE: {
      const { step, field, value } = action

      return {
        ...state,
        [step]: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(state as any)[step],
          [field]: value,
        },
      }
    }

    case SAVE_STEP: {
      const { step, values } = action

      return {
        ...state,
        [step]: values,
      }
    }

    case RECOVER_FORM: {
      return action.forms
    }

    default:
      return state
  }
}

export default subscription
