/* eslint-disable default-param-last, @typescript-eslint/default-param-last */
import { CHANGE_STEP } from '../actions/index'

const initialState = {
  currentStep: 0,
}

const stepper = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        ...state,
        currentStep: action.number,
      }
    default:
      return state
  }
}

export default stepper
