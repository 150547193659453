export default input => {
  const siren = input.replace(/ /g, '')
  if (siren === '000000000') return false
  var estValide
  if (siren.length !== 9 || isNaN(siren)) estValide = false
  else {
    // Donc le SIREN est un numérique à 9 chiffres
    var somme = 0
    var tmp
    for (var cpt = 0; cpt < siren.length; cpt++) {
      if (cpt % 2 === 1) {
        // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
        tmp = siren.charAt(cpt) * 2 // On le multiplie par 2
        if (tmp > 9) tmp -= 9 // Si le résultat est supérieur à 9, on lui soustrait 9
      } else tmp = siren.charAt(cpt)
      somme += parseInt(tmp)
    }
    if (somme % 10 === 0) estValide = true
    // Si la somme est un multiple de 10 alors le SIREN est valide
    else estValide = false
  }

  return estValide
}

export const isValidRNA = (input) => {
  return input.match(/^W\d{9}$/i)
}