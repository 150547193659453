import React, { Component } from 'react'

import './CustomerAreaDisplay.sass'

export default class CustomerAreaTitle extends Component {
  render() {
    const { label, icon, mobilePadding = false } = this.props

    return (
      <div className={`customer-area-title${mobilePadding ? ' mobile-padding' : ''}`}>
        {icon && <img src={`/assets/icons/${icon}.svg`} alt={label} />}
        {label}
      </div>
    )
  }
}
