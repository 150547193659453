import React, { Component } from 'react'
import countryCodes from 'Helpers/country-codes'
import { Field } from 'formik'

const CountryField = ({ errors, touched, fieldName }) => {
  const options = countryCodes.map(({ label, value }) => (
    <option value={value} key={value}>
      {label}
    </option>
  ))

  return (
    <div className="select-style">
      <Field name={fieldName} component="select" className="Input-group select">
        <option value="0" disabled>
          Pays*
        </option>
        {options}
      </Field>
      <span className="input-error-message select-error-message">
        {(touched[fieldName] && errors[fieldName]) || ''}
      </span>
    </div>
  )
}

export default CountryField