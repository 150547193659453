import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface CounterState {
  socialDenomination: string | null
}

const initialState: CounterState = {
  socialDenomination: null,
}

const onboardingSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setValues: (state, action: PayloadAction<Partial<CounterState>>) => {
      Object.keys(action.payload).forEach((key) => {
        // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
        (state as any)[key] = (action.payload as any)[key]
      })
    },
  },
})

export default onboardingSlice.reducer
