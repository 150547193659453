import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closeModal } from 'Actions/ui'

import './transfert-without-domi-modal.sass'

class TransfertWithoutDomiModal extends Component {
  render() {
    const { dispatch } = this.props

    return (
      <div className="TransfertWithoutDomiModal">
        <img
          onClick={() => {
            dispatch(closeModal())
          }}
          src="/assets/icons/icon-cross.svg"
          className="notif-modal-close"
          alt="fermer"
        />
        <div className="">
          <h3>Bon a savoir :</h3>
          <p>
            Sans domiciliation, votre adresse personnelle sera publique, et vous paierez un montant
            important de CFE (Cotisation Foncière des Entreprises).
          </p>
        </div>
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const MapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
  dispatch,
})

export default connect(
  MapStateToProps,
  MapDispatchToProps,
)(TransfertWithoutDomiModal)
