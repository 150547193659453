import React, { Component } from 'react'
import { throttle, get } from 'lodash'

import { checkAddress, formatAddress } from 'Helpers/geocode-utils'

import './address-geocode.sass'

class AddressGeocode extends Component {
  static defaultProps = {
    apiCallFrequency: 1000,
    minimumLength: 6,
  }

  constructor(props) {
    super(props)

    this.state = {
      value: get(props, 'field.value') || '',
      dropdownOpened: false,
      addresses: [],
    }

    this.throttleGeocode = throttle(this.geocodeAddress.bind(this), props.apiCallFrequency)
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'field.value') !== get(this.props, 'field.value')) {
      this.setState({
        value: get(this.props, 'field.value'),
        dropdownOpened: false,
      })
    }
  }

  geocodeAddress(address) {
    if (address.length < this.props.minimumLength) return

    fetch(`/api/geocode/get?address=${encodeURIComponent(address)}`)
      .then(res => res.json())
      .then(result => {
        const { onError } = this.props
        const { results } = result

        if (!(result.status === 'OK' && results.length)) {
          onError('Addresse introuvable, merci de vérifier votre saisie')

          return
        }

        const filteredAddresses = results.filter(a => !checkAddress(a))

        if (results.length && !filteredAddresses.length) {
          onError(checkAddress(results[0]))

          return
        }

        this.setState({
          addresses: filteredAddresses,
          dropdownOpened: true,
        })
      })
  }

  handleChange(value) {
    this.setState({ value })
    this.props.onChange(value)

    this.throttleGeocode(value)
  }

  renderDropDown() {
    const { dropdownOpened, addresses } = this.state
    const { onSelect } = this.props
    const { onChange } = this.props.field

    if (!dropdownOpened) return null

    const options = addresses.map(address => (
      <div
        onClick={() => {
          const formatted = formatAddress(address)

          this.setState({
            dropdownOpened: false,
            value: formatted.formattedAddress,
          })
          onChange(formatted.formattedAddress)
          onSelect(formatted)
        }}
        className="AddressGeocode-dropdown-address"
        key={address.place_id}
      >
        {address.formatted_address}
      </div>
    ))

    return (
      <div className="AddressGeocode-dropdown">
        <div className="AddressGeocode-dropdown-title">
          Merci de sélectionner votre adresse dans cette liste
        </div>
        {options}
      </div>
    )
  }

  render() {
    const { placeholder, field } = this.props

    return (
      <div className="AddressGeocode">
        <input
          type="text"
          name={field.name}
          value={this.state.value}
          onChange={e => this.handleChange(e.target.value)}
          placeholder={placeholder}
          onBlur={field.onBlur}
        />
        {this.renderDropDown()}
      </div>
    )
  }
}

export default AddressGeocode
