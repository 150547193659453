import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectStripe, Elements, StripeProvider } from 'react-stripe-elements'

import { setCurrentUser } from 'Actions/user'
import { notify } from 'Actions/notifications'
import fetchWrapper from 'Helpers/fetch-wrapper'
import GetCbForMainAccountModal from '../GetCbForMainAccountModal/GetCbForMainAccountModal'

import '../PaymentMeansPage/PaymentMeans.sass'
import { STRIPE_PUBLIC_KEY } from '../../../config/constants'

class UnpaidColisModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
    }
  }

  handlePayment(paymentMethodId) {
    const { dispatch, stripe, close } = this.props
    this.setState({ isSubmitting: true })
    return fetchWrapper(`/api/user/colis-invoice`, {
      method: 'POST',
      body: JSON.stringify({ paymentMethodId }),
    })
      .then(res => {
        const { validated, errorCode, client_secret, payIntId, invoiceId, colisIds, user } = res
        if (user) dispatch(setCurrentUser(user))

        if (validated) {
          dispatch(notify('success', 'Paiement validé.'))
          close()
        } else {
          if (errorCode === 'card_declined') {
            dispatch(notify('error', 'Le paiement a échoué, veuillez vérifier votre moyen de paiement.'))
            this.setState({ isSubmitting: false })
            return
          }

          return stripe.handleCardPayment(client_secret, {}).then(res => {
            console.log('res handleCardPayment ------------------------------------------>')
            console.log(res)

            return fetchWrapper(`/api/user/colis-invoice/payment-confirmed`, {
              method: 'POST',
              body: JSON.stringify({ payIntId, invoiceId, colisIds }),
            }).then(res => {
              if (res.validated) {
                dispatch(notify('success', 'Paiement validé.'))
                close()
              } else {
                dispatch(notify('error', 'Le paiement a échoué, veuillez vérifier votre moyen de paiement.'))
                this.setState({ isSubmitting: false })
              }
            })
          })
        }
      })
      .catch(e => {
        console.log('e ------------------------------------------>')
        console.log(e)
        this.props.dispatch(
          notify('error', 'Une erreur est survenue.'),
        )
      })
  }

  render() {
    if (this.state.isSubmitting) {
      return (
        <div className="means-modal">
          <div className="mb-12">Traitement en cours...</div>    
        </div>
      )
    }

    return <GetCbForMainAccountModal
      onValidate={this.handlePayment.bind(this)}
      title={<div className="mb-12">Vous avez un ou plusieurs colis en attente, merci d'entrer vos informations de paiement pour la facturation des colis</div>}
    />
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

const ConnectedComponent = connect(mapStateToProps)(injectStripe(UnpaidColisModal))

const ModalWrapper = props => (
  <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
    <Elements locale="fr">
      <ConnectedComponent {...props} />
    </Elements>
  </StripeProvider>
)






export default ModalWrapper
