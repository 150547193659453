import React from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'

import EditAddressModal from '../CustomerArea/AdressPage/EditAddressModal'
import EditCbModal from '../CustomerArea/PaymentMeansPage/EditCbModal'
import EditIbanModal from '../CustomerArea/PaymentMeansPage/EditIbanModal'
import NotificationModal from '../CustomerArea/NotificationModal/NotificationModal'
import ConfirmPaymentIntervalModal from '../CustomerArea/SubscriptionPage/ConfirmPaymentIntervalModal'
import ConfirmScanModal from '../CustomerArea/SubscriptionPage/ConfirmScanModal'
import AmlModal from '../CustomerArea/AmlModal/AmlModal'
import KbisModal from '../CustomerArea/KbisModal/KbisModal'
import StatutsModal from '../CustomerArea/StatutsModal/StatutsModal'
import WrongSirenModal from '../CustomerArea/WrongSirenModal/WrongSirenModal'
import ProcurationModal from '../CustomerArea/ProcurationModal/ProcurationModal'
import AttestationModal from '../CustomerArea/YousignModals/AttestationModal'
import AnnualOfferSignatureModal from '../CustomerArea/YousignModals/AnnualOfferSignatureModal'
import AnnualOfferModal from '../CustomerArea/AnnualOfferModal/AnnualOfferModal'
import TransfertWithoutDomiModal from '../homeTransfert/TransfertWithoutDomiModal/TransfertWithoutDomiModal'
import StripeElementsModal from '../../components-v2/StripeElementsModal'
import PendingInvoicesPaymentModal from '../../components-v2/PendingInvoicesPaymentModal'
import PpeOriginModal from '../CustomerArea/PpeOriginModal/PpeOriginModal'
import ChoixDomiciliationModal from '../CustomerArea/ChoixDomiciliationModal/ChoixDomiciliationModal'
import GetCbForMainAccountModal from '../CustomerArea/GetCbForMainAccountModal/GetCbForMainAccountModal'
import UnpaidColisModal from '../CustomerArea/UnpaidColisModal/UnpaidColisModal'
import RepresentantConfirmationModal from '../../components-v2/modals/RepresentantConfirmationModal/RepresentantConfirmationModal'
import { closeModal } from '../../actions/ui'

import './modal.sass'

const modalInfo = {
  editAddress: {
    component: props => <EditAddressModal {...props} />,
  },
  editCB: {
    component: props => <EditCbModal {...props} />,
  },
  editIBAN: {
    component: () => <EditIbanModal />,
  },
  aml: {
    component: props => <AmlModal {...props} />,
  },
  notification: {
    component: props => <NotificationModal {...props} />,
  },
  confirmPaymentInterval: {
    component: props => <ConfirmPaymentIntervalModal {...props} />,
  },
  confirmPaymentScan: {
    component: props => <ConfirmScanModal {...props} />,
  },
  procuration: {
    component: props => <ProcurationModal {...props} />,
  },
  transfertWithoutDomi: {
    component: props => <TransfertWithoutDomiModal {...props} />,
  },
  blackFridayAnnualOffer: {
    component: props => <AnnualOfferModal {...props} />,
  },
  kbis: {
    component: props => <KbisModal {...props} />,
  },
  statuts: {
    component: props => <StatutsModal {...props} />,
  },
  stripeElements: {
    component: props => <StripeElementsModal {...props} />,
  },
  wrongSiren: {
    component: props => <WrongSirenModal {...props} />,
  },
  attestation: {
    component: props => <AttestationModal {...props} />,
  },
  annualOfferSignature: {
    component: props => <AnnualOfferSignatureModal {...props} />,
  },
  ppeOrigin: {
    component: props => <PpeOriginModal {...props} />,
  },
  choixDomiciliation: {
    component: props => <ChoixDomiciliationModal {...props} />,
  },
  representantConfirmation: {
    component: props => <RepresentantConfirmationModal {...props} />,
  },
  getCbForMainAccountModal: {
    component: props => <GetCbForMainAccountModal {...props} />,
  },
  pendingInvoicesPaymentModal: {
    component: props => <PendingInvoicesPaymentModal {...props} />,
  },
  unpaidColis: {
    component: props => <UnpaidColisModal {...props} />,
  },
}

class Modal extends React.Component {
  render() {
    const { activeModal, close } = this.props
    if (!activeModal) return null

    const { modalProps = {}, modalName } = activeModal
    const modalComponent = get(modalInfo, modalName)

    if (!modalComponent) throw new Error(`No modal matches this name : ${modalName}`)

    const handleClose = () => {
      close()
      if (modalProps.afterClose) modalProps.afterClose()
    }

    return (
      <div className="modal">
        <div className="modal-background" onClick={() => !modalProps.unclosable && handleClose()} />
        <div className="modal-body main-content">{modalComponent.component({ ...modalProps, close: handleClose })}</div>
      </div>
    )
  }
}

const MapStateToProps = state => ({
  activeModal: state.ui.modalsQueue[0],
})

const MapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
})

export default connect(
  MapStateToProps,
  MapDispatchToProps,
)(Modal)
