import { NOTIFY, FADE_NOTIFICATION } from './index'

export const notify = (notifyType, message, fadeOut) => ({
  type: NOTIFY,
  notifyType,
  message,
  fadeOut,
})

export const fadeNotification = () => ({
  type: FADE_NOTIFICATION,
})
