import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { omit } from 'lodash'

import { notify } from 'Actions/notifications'
import { setCurrentUser } from 'Actions/user'
import isValidSiren, { isValidRNA } from 'Helpers/siren-validator'
import SubmitSpinnerButton from '../../SubscriptionPage/steps/SubmitSpinnerButton'
import { getUser } from '../../../api/user'
import { requiredFieldsForAMLPopup } from '../CustomerArea'
import AmlModalStep1 from './AmlModalStep1'
import AmlModalStep3 from './AmlModalStep3'

import 'Ui/input.sass'
import '../../SubscriptionPage/steps/step.sass'
import '../AdressPage/editAddressModal.sass'
import './aml-modal.sass'

class AmlModal extends Component {
  
  refreshUser() {
    const { dispatch, close } = this.props
    
    getUser(res => {
      dispatch(setCurrentUser(res))

      if (
        (res.siren || res.rna)
          && (requiredFieldsForAMLPopup.every(field => res[field] !== null))
          && (!res.beneficiairesNeedingJustif.length
            || res.juridic_form === 'EI'
            || res.juridic_form === 'Micro-entreprise'
          )
      ) {
        close()
      }
    })
  }
  
  handleSubmitSiren(values, actions) {
    const { dispatch, currentUser } = this.props

    fetch(`/api/user/${currentUser.id}/siren`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        siren: values.siren,
        rna: values.rna,
      }),
    })
      .then(res => {
        actions.setSubmitting(false)
        
        if (res.status === 400) {
          dispatch(notify('error', 'Merci de renseigner le SIREN de VOTRE société, et non pas le nôtre', 6000))
          throw null
        }
        if (res.status !== 200) throw new Error(res.error)

        return res
      })
      .then(res => res.json())
      .then(res => {
        if (res.result === 'BLOCKED_DUPLICATE') {
          // just refresh the page, blocking is handled when displaying CustomerArea
          window.location.reload()
          return
        }

        dispatch(notify('success', 'Modification enregistrée.'))
        // refresh page after siren/rna update, because we need to check if kbis and status modals are now required for this user
        window.location.reload()
      })
      .catch((e) => {
        console.log('e ------------------------------------------>')
        console.log(e)
        if (e) dispatch(notify('error', 'Une erreur est survenue.'))
      })
  }
  
  renderSirenQuestion() {
    const { unclosable, currentUser } = this.props
    
    return (
      <>
        <div className="label-form">
          <span className="step-subtitle lh22">Merci de nous communiquer votre numéro SIREN dès que vous l'aurez reçu.</span>
        </div>
        <Formik
          initialValues={{ siren: '', rna: '' }}
          validate={values => {
            const errors = {}

            if (!values.rna && !isValidSiren(values.siren)) {
              errors.siren = 'Numéro de siren invalide.'
            }

            if (values.rna && !isValidRNA(values.rna)) {
              errors.rna = 'Numéro RNA invalide.'
            }

            if (!values.siren && !values.rna) {
              errors.siren = currentUser.juridic_form === 'Association'
                ? 'Vous devez renseigner au moins une valeur entre Siren et RNA'
                : 'Vous devez renseigner une valeur pour votre siren'
            }

            ;(['siren', 'rna']).forEach(fieldName => {
              if (values[fieldName].includes(' ')) {
                errors[fieldName] = 'Merci d’enlever les espaces.'
              }
            })

            return errors
          }}
          onSubmit={(values, actions) => this.handleSubmitSiren(values, actions)}
          render={({ touched, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
            return (
              <Form>
                <div className="edit-address-input">
                  <div className="input">
                    <Field
                      name="siren"
                      type="text"
                      className="Input-group"
                      placeholder="Numéro Siren"
                      maxLength="9"
                      onChange={e => {
                        if (e.target.value.includes(' ')) setFieldTouched('siren', true)
                        setFieldValue('siren', e.target.value)
                      }}
                    />
                    <span className="input-error-message">
                      {(touched.siren && errors.siren) || ''}
                    </span>
                  </div>
                </div>
                {unclosable ? (
                  <div className="no-siren-message">
                    Si vous n'avez toujours pas de SIREN, merci d'adresser un email à
                    contact@ma-domiciliation.fr pour débloquer votre compte.
                  </div>
                ) : null}

                {currentUser.juridic_form === 'Association' && (
                  <>
                    <div className="label-form">
                      <span className="step-subtitle lh22">Si vous avez uniquement un numéro RNA, veuillez le renseigner ici :</span>
                    </div>
                    <div className="edit-address-input">
                      <div className="input">
                        <Field
                          name="rna"
                          type="text"
                          className="Input-group"
                          placeholder="Numéro RNA"
                          maxLength="10"
                          onChange={e => {
                            if (e.target.value.includes(' ')) setFieldTouched('rna', true)
                            setFieldValue('rna', e.target.value)
                          }}
                        />
                        <span className="input-error-message">
                          {(touched.rna && errors.rna) || ''}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="right-container mb-32">
                  <SubmitSpinnerButton isSubmitting={isSubmitting}>
                    <button type="submit" className="btn valid small-height">
                      Valider
                    </button>
                  </SubmitSpinnerButton>
                </div>
              </Form>
            )
          }}
        />
      </>
    )
  }

  renderStep2() {
    const { currentUser } = this.props
    
    return (
      <>
        <h3>Mise à jour réglementaire de votre fiche client</h3>
        {!currentUser.siren && this.renderSirenQuestion()}
      </>
    )
  }
  
  renderStep1() {
    return (
      <AmlModalStep1 refreshUser={() => this.refreshUser()} />
    )
  }
  
  renderStep3() {
    return (
      <AmlModalStep3 refreshUser={() => this.refreshUser()} />
    )
  }
  
  render() {
    const { unclosable, currentUser } = this.props
    let displayLaterButton = false
    
    let renderStep = this.renderStep1.bind(this)
    if (
      requiredFieldsForAMLPopup
        .filter(field => field !== 'siren')
        .every(field => currentUser[field] !== null)
    ) {
      if (!currentUser.siren && !currentUser.rna) {
        renderStep = this.renderStep2.bind(this)
        if (!unclosable) displayLaterButton = true
      } else {
        renderStep = this.renderStep3.bind(this)
      }
    }

    return (
      <div className="AmlModal">
        {renderStep()}
        {displayLaterButton ? (
          <div className="mt-32 center-container">
            <button className="btn valid small-height close-btn" onClick={() => this.props.close()}>
              Plus tard
            </button>
          </div>
        ) : null}
      </div>
    )
  }
}

const MapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(MapStateToProps)(AmlModal)
