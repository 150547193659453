import React, { Component } from 'react'
import { Elements } from 'react-stripe-elements'
import { connect } from 'react-redux'
import { values, identity } from 'lodash'

import axiosClient from '@api/axios-client'
import { setCurrentUser } from 'Actions/user'
import { closeModal } from 'Actions/ui'
import { notify } from 'Actions/notifications'
import { StripeProvider } from 'react-stripe-elements'
import { STRIPE_PUBLIC_KEY, WEBSITE_URL } from 'Config/constants'
import StripePaymentElement from '@components/StripePaymentElement'
import fetchWrapper from 'Helpers/fetch-wrapper'
import get from 'lodash/get'

import './PaymentMeans.sass'

class EditCbModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isValidating: false,
      secrets: null,
      stripe: null,
      clientSecret: null
    }
  }

  async componentDidMount() {
    const res = await axiosClient.get('/api/user/paymentIntent')
    this.setState({
      clientSecret: res.data.clientSecret
    })
  }

  async handleCbSubmit({ paymentMethodId, stripe }) {
    const { dispatch, currentUser, close } = this.props

    try {
      const res = await fetchWrapper(`/api/user/${currentUser.id}/cb`, {
        method: 'PUT',
        body: JSON.stringify({ paymentMethodId }),
      })

      console.log('res --------------------------------->')
      console.log(res.user)

      dispatch(setCurrentUser(res.user))

      if (res.pendingPayments) {
        // not very clean, need to keep cb form opened to keep access to stripe variable for further payments
        // so just hide the form and show validation message, and keep stripe variable in state because it's not accessible in props anymore when validating (may be refactored now that we use new stripe lib)
        this.setState({ isValidating: true, secrets: res.pendingPayments, stripe })

        return
      }

      dispatch(notify('success', 'Modification enregistrée !'))
    } catch (e) {
      console.log('e --------------------------------->')
      console.log(e)
      const message = e.message || 'Carte refusée, merci de vérifier les informations entrées.'
      dispatch(notify('error', message))
    }

    close()
  }

  handleSuccessivePayments(secrets, step = 0) {
    const { dispatch } = this.props
    const { stripe } = this.state

    stripe.handleCardPayment(secrets[step], {}).then(res => {
      if (res.error) {
        dispatch(closeModal())
        dispatch(
          notify('error', 'Paiement refusé, veuillez vérifier l’approvisionnement de votre carte.'),
        )

        return
      }

      if (step < secrets.length - 1) {
        dispatch(notify('success', 'Paiement validé'))
        this.handleSuccessivePayments(secrets, step + 1)

        return
      }

      dispatch(closeModal())

      this.checkPaymentsStatuses()
    })
  }

  checkPaymentsStatuses() {
    const { dispatch, currentUser } = this.props

    fetch(`/api/user/${currentUser.id}/check-invoices-paid`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    })
      .then(res => {
        if (res.status !== 200) throw new Error(res.error)

        dispatch(
          notify(
            'success',
            'Tous les paiements en attente ont été validés. Réactivation de votre compte...',
          ),
        )

        return res
      })
      .then(res => res.json())
      .then(user => dispatch(setCurrentUser(user)))
  }

  renderValidating() {
    // handle 3DS for all invoices
    const { secrets } = this.state
    const nb = secrets.length

    return (
      <div className="">
        <div className="notif-modal-message">{`Vous avez ${nb} facture(s) en attente de paiement. Afin de régulariser votre situation, il vous sera demandé de valider par 3D secure les ${nb} paiement(s).`}</div>
        <button
          className="payment-label-submit btn valid"
          onClick={() => this.handleSuccessivePayments(secrets)}
        >
          Valider les paiements
        </button>
      </div>
    )
  }

  render() {
    const { isValidating, clientSecret } = this.state
    if (!clientSecret) return null

    const isValidatingClass = isValidating ? ' means-modal-validating' : ''
    const stripeAccount = get(this.props, 'currentUser.company.idStripeAccount', undefined)
      ? get(this.props, 'currentUser.company.idStripeAccount', undefined)
      : undefined

    return (
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY} stripeAccount={stripeAccount}>
        <div className={`means-modal${isValidatingClass}`}>
          {isValidating
            ? this.renderValidating()
            : (
              <StripePaymentElement
                stripeAccount={stripeAccount}
                clientSecret={clientSecret}
                isOffSession={true}
                onPaymentMeanSuccess={this.handleCbSubmit.bind(this)}
                returnUrl={`${WEBSITE_URL}/mon-compte/paiement`}
              />
          )}
        </div>
      </StripeProvider>
    )
  }
}


const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(EditCbModal)
