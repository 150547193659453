import moment from 'moment'

export default scanDate => {
  const date = moment(scanDate)

  if (moment(scanDate).endOf('month').date() - date.date() > 3) {
    return moment(date)
      .startOf('month')
      .add(1, 'month')
  }

  return moment(date)
    .startOf('month')
    .add(2, 'month')
}

// Next day, except weekends
export const calculUrgentReexpeditionDate = date => {
  const reexpDate = moment(date).add(1, 'day')

  if (reexpDate.day() === 6) reexpDate.day(8)
  if (reexpDate.day() === 0) reexpDate.day(1)

  return reexpDate
}
