import React, { Component } from 'react'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'moment/locale/fr'

import 'react-dates/lib/css/_datepicker.css'
import 'Ui/customDatePicker.sass'

export default class CustomDateRangePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
    }
  }

  render() {
    const { startDate, endDate, onChange } = this.props

    return (
      <div className="custom-date-range-picker">
        <DateRangePicker
          startDate={startDate}
          startDateId="letter-start-date"
          endDate={endDate}
          endDateId="letter-end-date"
          onDatesChange={onChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          showDefaultInputIcon={true}
          inputIconPosition="after"
          numberOfMonths={1}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={day => moment().diff(day) < 0}
        />
      </div>
    )
  }
}
