import { useEffect, useState } from 'react'
import StripePaymentElement, { StripePaymentElementProps } from '@components/StripePaymentElement'
import axiosClient from '@api/axios-client'

type PendingStripeInvoice = {
  user_id: number
  invoice_id: string
  stripe_account: string
  product_type: string
}

type Props = {
  pendingStripeInvoices: PendingStripeInvoice[]
  close: () => void
}

const PendingInvoicesPaymentModal = ({ close, pendingStripeInvoices }: Props) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [invoiceId, setInvoiceId] = useState<string | null>(null)
  const [invoiceAmount, setInvoiceAmount] = useState<number | null>(null)
  const colisInvoices = pendingStripeInvoices.filter(i => i.product_type === 'colis')
  const courriersInvoices = pendingStripeInvoices.filter(i => i.product_type === 'dépassement-courriers')

  const getFirstInvoiceClientSecret = async () => {
    try {
      const res = await axiosClient.get('/api/user/payment-intent-pending-invoice')
      setClientSecret(res.data.clientSecret)
      setInvoiceId(res.data.invoiceId)
      setInvoiceAmount(res.data.amount)
    } catch (e) {
      console.log('e --------------------------------->')
      console.log(e)
      close()
    }
  }

  const handlePaymentSuccess = async ({ paymentIntentId }: { paymentIntentId: string }) => {
    try {
      await axiosClient.post('/api/user/confirm-pending-invoice-payment', {
        paymentIntentId,
        invoiceId,
      })
    } catch (e) {
      console.log('handlePaymentSuccess error : ', e)
    }
    window.location.reload()
  }

  useEffect(() => {
    getFirstInvoiceClientSecret()
  }, [])

  if (!clientSecret) return null

  let text = colisInvoices.length ? `${colisInvoices.length} colis` : ''
  if (colisInvoices.length && courriersInvoices.length) text += ' et '
  if (courriersInvoices.length) text += `${courriersInvoices.length} facture(s) de courriers`

  const title = `Vous avez ${text} en attente, merci d'entrer vos informations de paiement pour la facturation.`
  const subtitle = `Paiement de la première facture d'un montant de ${((invoiceAmount || 0) / 100).toFixed(2).replace('.', ',')}€ TTC :`

  return (
    <div className="p-8 gap-4 md:w-[512px]">
      <div className="mb-6">
        {title}<br /><br />{subtitle}
      </div>
      <StripePaymentElement
        clientSecret={clientSecret}
        onPaymentSuccess={handlePaymentSuccess}
        stripeAccount={null}
        close={close}
      />
    </div>
  )
}

export default PendingInvoicesPaymentModal
