export default (url, { method = 'GET', body } = {}) =>
  fetch(url, {
    method,
    body,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then(res => {
      if (res.status === 403) {
        window.location.href = '/login'
        return
      }
      if (res.status !== 200) throw new Error(res.error)

      return res
    })
    .then(res => res.json())
